import { Button, Container, Typography } from '@material-ui/core';
import React, { useState, useEffect } from 'react';
import { Redirect, useParams } from 'react-router-dom';
import { PasswordField } from '../../components';
import { notificationService } from '../../services';
import { resetPassword } from '../../services/user';

function validatePassword(password) {
  if (typeof password !== 'string') {
    return false;
  }

  if (password.length < 8) return false;

  return true;
}

const PASSWORD_ERROR = 'Le mot de passe doit faire au moins 8 caractères.';

export function ResetPassword() {
  const { token } = useParams();
  const [password, setPassword] = useState('');
  const [error, setError] = useState(null);
  const [touched, setTouched] = useState(false);
  const [redirect, setRedict] = useState(false);

  const handleSubmit = event => {
    event.preventDefault();

    if (!validatePassword(password)) {
      setError(PASSWORD_ERROR);
      setTouched(true);
      return;
    }

    setError(null);
    resetPassword(token, password)
      .then(() => {
        notificationService.addNotification(
          'Mot de passe changé avec succès !'
        );
        setRedict(true);
      })
      .catch(() => {
        notificationService.addNotification("Une erreur s'est produite.");
      });
  };

  useEffect(() => {
    if (validatePassword(password)) {
      setError(null);
      return;
    }

    setError(PASSWORD_ERROR);
  }, [password]);

  if (redirect) {
    return <Redirect path='/sign-in' />;
  }

  return (
    <Container
      maxWidth='xs'
      style={{
        marginTop: 64,
        paddingBlock: 24,
        borderRadius: 16,
        border: '1px solid rgb(220, 220, 220)',
        boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px',
      }}
    >
      <form onSubmit={handleSubmit} action='#'>
        <Typography variant='h4' as='h1'>
          Mot de passe oublié ?
        </Typography>
        <PasswordField
          fullWidth
          id='password'
          onChange={event => {
            setPassword(event.target.value);
          }}
          onBlur={() => setTouched(true)}
          error={Boolean(error) && touched}
          helperText={touched && error}
          style={{ minHeight: 90 }}
        />

        <Button fullWidth variant='contained' color='primary'>
          Changer mon mot de passe
        </Button>
      </form>
    </Container>
  );
}
