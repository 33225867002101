import React, { useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { Link } from 'react-router-dom';
import {
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  makeStyles,
  Drawer,
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Container,
  Box,
} from '@material-ui/core';

import MenuIcon from '@material-ui/icons/Menu';
import PersonIcon from '@material-ui/icons/Person';
import Equalizer from '@material-ui/icons/Equalizer';
import School from '@material-ui/icons/School';
import HomeIcon from '@material-ui/icons/Home';

import { notificationService } from '../services';
import { Restricted, Notifications } from '../components';
import { useAuth } from '../contexts/auth';

const drawerWidth = 240;

const useStyles = makeStyles(theme => ({
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    backgroundColor: 'white', // To change when using a theme, I suppose
    boxShadow: '0 0 10px black',
  },
  menuButton: {
    right: theme.spacing(2),
    '&:hover $menuIcon': {
      color: '#FFA911',
    },
  },

  menuIcon: {
    fontSize: '1.5em',
    transition: '0.3s ease',
  },
  title: {
    flexGrow: 1,
    '& a': {
      fontSize: '1em',
      textTransform: 'uppercase',
      letterSpacing: '0.15em',
      fontWeight: 900,
      color: '#585858',
      textDecoration: 'none',
      transition: '0.3s ease',
    },
    '& a:hover': {
      color: '#FFA911',
    },
  },

  titleHighlight: {
    color: '#FFA911',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
  },
  drawerItem: {
    '&:hover': {
      color: '#FFA911',
    },
  },
  drawerOpen: {
    width: drawerWidth,
  },
  drawerPaper: {
    width: drawerWidth,
    background: '#585858',
    color: 'white',
  },
  drawerClose: {
    overflowX: 'hidden',
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9) + 1,
    },
  },
  drawerBackdrop: {
    background: 'rgba(255, 255, 255, 0.2)',
  },
  menuDividerRoot: {
    backgroundColor: 'rgba(255, 255, 255, 0.15)',
  },
}));

const NavBar = ({ setBlurred }) => {
  const [open, setOpen] = useState(false);
  const classes = useStyles();
  const { signout } = useAuth();

  const handleDrawer = () => {
    setOpen(!open);
    setBlurred(!open);
  };

  return (
    <div>
      {/* Show notifications */}
      <Notifications />

      {/* The bar at the top of the app */}
      <AppBar position='static' className={classes.appBar}>
        <Toolbar>
          <Typography variant='h4' className={classes.title}>
            <Link to='/'>
              Sh<span className={classes.titleHighlight}>a</span>r
              <span className={classes.titleHighlight}>i</span>ng
            </Link>
          </Typography>
          <IconButton
            edge='start'
            className={classes.menuButton}
            color='inherit'
            aria-label='menu'
            onClick={handleDrawer}
          >
            <MenuIcon className={classes.menuIcon} color='action' />
          </IconButton>
        </Toolbar>
      </AppBar>

      {/* The navbar that pops on the right */}
      <Drawer
        anchor='right'
        open={open}
        onClose={handleDrawer}
        className={clsx(classes.drawer, {
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
        })}
        classes={{
          paper: classes.drawerPaper,
        }}
        BackdropProps={{
          classes: {
            root: classes.drawerBackdrop,
          },
        }}
      >
        <Container>
          <Box
            mt={3}
            mb={2}
            display='flex'
            justifyContent='center'
            alignItems='center'
          >
            <Typography variant='h4'>Menu</Typography>
          </Box>
          <List>
            <ListItem
              button
              key='home'
              component={Link}
              to='/'
              onClick={handleDrawer}
              className={classes.drawerItem}
            >
              <ListItemIcon>
                <HomeIcon style={{ color: 'white' }} />
              </ListItemIcon>
              <ListItemText primary='Accueil' />
            </ListItem>
            <Divider
              classes={{ root: classes.menuDividerRoot }}
              variant='inset'
              component='li'
            />

            <ListItem
              button
              key='learn'
              component={Link}
              to='/learn'
              onClick={handleDrawer}
              className={classes.drawerItem}
            >
              <ListItemIcon>
                <School style={{ color: 'white' }} />
              </ListItemIcon>
              <ListItemText primary='Learn' />
            </ListItem>
            <Divider
              classes={{ root: classes.menuDividerRoot }}
              variant='inset'
              component='li'
            />

            {/* <ListItem
              button
              key='train'
              component={Link}
              to='/train'
              onClick={handleDrawer}
              className={classes.drawerItem}
            >
              <ListItemIcon>
                <HomeIcon style={{ color: 'white' }} />
              </ListItemIcon>
              <ListItemText primary='Train' />
            </ListItem>
            <Divider
              classes={{ root: classes.menuDividerRoot }}
              variant='inset'
              component='li'
            /> */}

            <ListItem
              button
              key='Compete'
              component={Link}
              to='/compete'
              onClick={handleDrawer}
              className={classes.drawerItem}
            >
              <ListItemIcon>
                <Equalizer style={{ color: 'white' }} />
              </ListItemIcon>
              <ListItemText primary='Compete' />
            </ListItem>
            <Divider
              classes={{ root: classes.menuDividerRoot }}
              variant='inset'
              component='li'
            />

            {/* For connected user only */}
            <Restricted>
              <ListItem
                button
                key='profile'
                component={Link}
                to='/profile'
                onClick={handleDrawer}
                className={classes.drawerItem}
              >
                <ListItemIcon>
                  <PersonIcon style={{ color: 'white' }} />
                </ListItemIcon>
                <ListItemText primary='Mon profil' />
              </ListItem>

              <Divider
                classes={{ root: classes.menuDividerRoot }}
                variant='inset'
                component='li'
              />

              {/* For admin only */}
              <Restricted requiredRole='admin'>
                <ListItem
                  button
                  key='admin'
                  to='/admin'
                  component={Link}
                  onClick={handleDrawer}
                  className={classes.drawerItem}
                >
                  <ListItemText>Interface admin</ListItemText>
                </ListItem>

                <Divider
                  classes={{ root: classes.menuDividerRoot }}
                  variant='inset'
                  component='li'
                />
              </Restricted>

              <ListItem
                button
                key='signout'
                to='/'
                component={Link}
                onClick={e => {
                  handleDrawer(e);
                  signout();
                  notificationService.addNotification(
                    'Vous avez été déconnecté correctement !'
                  );
                }}
                className={classes.drawerItem}
              >
                <ListItemText>Se déconnecter</ListItemText>
              </ListItem>

              <Divider
                classes={{ root: classes.menuDividerRoot }}
                variant='inset'
                component='li'
              />
            </Restricted>

            <Restricted unconnectedOnly>
              <ListItem
                button
                key='signin'
                component={Link}
                to='/signin'
                onClick={handleDrawer}
                className={classes.drawerItem}
              >
                <ListItemText primary='Se connecter' />
              </ListItem>

              <Divider
                classes={{ root: classes.menuDividerRoot }}
                variant='inset'
                component='li'
              />

              <ListItem
                button
                key='signup'
                component={Link}
                to='/signup'
                onClick={handleDrawer}
                className={classes.drawerItem}
              >
                <ListItemText primary='Créer un compte' />
              </ListItem>

              <Divider
                classes={{ root: classes.menuDividerRoot }}
                variant='inset'
                component='li'
              />
            </Restricted>
          </List>
        </Container>
      </Drawer>
    </div>
  );
};

NavBar.propTypes = {
  // Active blurred effect when menu is open
  setBlurred: PropTypes.func.isRequired,
};

export default NavBar;
