import React from 'react';
import {
  Table,
  TableContainer,
  TableHead,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from '@material-ui/core';

const competitionState = (start, end) => {
  if (end < new Date()) return 'Terminé';
  if (start < new Date()) return 'En cours';
  return 'Bientôt';
};

export function CompetitionsList({ competitions, onSelect }) {
  return (
    <div>
      <Typography variant='h5'>Liste des compétitions</Typography>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>id</TableCell>
              <TableCell>Titre</TableCell>
              <TableCell>Type</TableCell>
              <TableCell>Début</TableCell>
              <TableCell>Fin</TableCell>
              <TableCell>Statut</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {competitions.map(({ id, title, type, start, end }) => (
              <TableRow key={id} onClick={() => onSelect(id)}>
                <TableCell>#{id}</TableCell>
                <TableCell>{title}</TableCell>
                <TableCell>{type}</TableCell>
                <TableCell>{start.toLocaleString()}</TableCell>
                <TableCell>{end.toLocaleString()}</TableCell>
                <TableCell>{competitionState(start, end)}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}
