import PropTypes from 'prop-types';
import { useAuth } from '../contexts/auth';

const Restricted = ({ children, requiredRole, unconnectedOnly }) => {
  const { currentUser } = useAuth();

  if (unconnectedOnly && !currentUser) return children;
  if (unconnectedOnly) return null;

  if (!currentUser) return null;
  if (requiredRole && requiredRole !== currentUser.role) return null;
  return children;
};

Restricted.propTypes = {
  children: PropTypes.node.isRequired,
  requiredRole: PropTypes.string,
  unconnectedOnly: PropTypes.bool,
};

export default Restricted;
