import { BehaviorSubject } from 'rxjs';
import axios from 'axios';

// New stuff
export const api = axios.create({ withCredentials: true });

export function getAccessToken() {
  return api
    .get('/api/auth/refresh-token')
    .then(response => response.data.token);
}

export async function renewAccessToken() {
  const token = await getAccessToken();
  api.defaults.headers.common.Authorization = `Bearer ${token}`;
  return token;
}

let renewTokenPromise;

api.interceptors.response.use(
  response => response,
  error => {
    // Return any error which is not due to authentication back to the calling service
    if (
      axios.isCancel(error) ||
      (error.response && error.response.status !== 401)
    ) {
      return Promise.reject(error);
    }

    // Signout the user if refreshing the access token didn't work
    if (error.config.url === '/auth/refresh-token') {
      // TODO: Do stuff

      return Promise.reject(error);
    }

    // Try to renew the access token
    if (!renewTokenPromise) {
      renewTokenPromise = renewAccessToken();
    }

    return renewTokenPromise.then(token => {
      const { config } = error;
      config.headers.Authorization = `Bearer ${token}`;

      return api.request(config);
    });
  }
);

export function signin(username, password) {
  return axios
    .post('/api/auth/sign-in', { username, password })
    .then(response => {
      const { token } = response.data;
      api.defaults.headers.common.Authorization = `Bearer ${token}`;
    });
}

export function signout() {
  return api.post('/api/auth/sign-out');
}

export async function signup(username, password) {
  return api
    .post('/api/user', {
      username,
      password,
    })
    .then(response => response.data);
}

// Old stuff

const currentUserSubject = new BehaviorSubject(
  JSON.parse(localStorage.getItem('currentUser'))
);

// const updateUser = newUser => {
//   localStorage.setItem('currentUser', JSON.stringify(newUser));
//   currentUserSubject.next(newUser);
// };

// const signin = (username, password) => {
//   return axios.post('/auth/signin', { username, password }).then(res => {
//     updateUser(res.data);

//     return res.data;
//   });
// };

// const signout = () => {
//   localStorage.removeItem('currentUser');
//   currentUserSubject.next(null);
// };

// const isTokenValid = () => {
//   if (!currentUserSubject.value) return;
//   axios
//     .post('/auth/is-valid', { token: currentUserSubject.value.token })
//     .then(res => {
//       if (!res.data.valid) signout();
//     });
// };

export default {
  signup,
  signin,
  signout,
  // isTokenValid,
  // updateUser,
  currentUser: currentUserSubject.asObservable(),
  get currentUserValue() {
    return currentUserSubject.value;
  },
  renewAccessToken,
};
