import React from 'react';

const TrainHome = () => {
  return (
    <div>
      <h1>Train Home (WIP)</h1>
    </div>
  );
};

export default TrainHome;
