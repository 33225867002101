import {
  Button,
  Grid,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
} from '@material-ui/core';
import React, { useState } from 'react';
import { competitionsService } from '../../../../services';

export function CompetitionSubmitDialog({ competitionId, ...props }) {
  const [file, setFile] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [result, setResult] = useState(null);

  const handleSubmit = async () => {
    setIsSubmitting(true);
    setResult(null);
    setResult(await competitionsService.blankSubmit(competitionId, file));
    setIsSubmitting(false);
  };

  const handleClose = () => {
    setResult(null);
    props.onClose();
  };

  return (
    <Dialog {...props} maxWidth='lg' fullWidth onClose={handleClose}>
      <DialogTitle>Soumettre des résultats</DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12} md={8}>
            <Button fullWidth variant='outlined' component='label'>
              Soumission
              <input
                type='file'
                onChange={e => {
                  setFile(e.target.files[0]);
                }}
                style={{ display: 'none' }}
              />
            </Button>
          </Grid>

          <Grid item xs={12} md={4}>
            <Button
              fullWidth
              disabled={!file}
              onClick={handleSubmit}
              variant='contained'
              color='primary'
            >
              Soumettre
            </Button>
          </Grid>
        </Grid>

        {isSubmitting && (
          <DialogContentText>Magic operating ...</DialogContentText>
        )}

        {result && (
          <>
            <Divider style={{ marginBlock: 8 }} />
            {result.success ? (
              <DialogContentText>Score: {result.score}</DialogContentText>
            ) : (
              <DialogContentText>Erreur: {result.error}</DialogContentText>
            )}
          </>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Quitter</Button>
      </DialogActions>
    </Dialog>
  );
}
