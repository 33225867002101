import React, { useState } from 'react';
import { Redirect, Link as RouterLink } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from 'yup';

import {
  Container,
  makeStyles,
  TextField,
  Button,
  Link,
  Typography,
} from '@material-ui/core';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';

import { notificationService } from '../services';
import PasswordField from './PasswordField';
import { useAuth } from '../contexts/auth';

const useStyle = makeStyles(theme => ({
  form: {
    marginTop: theme.spacing(3),
    '& > *': {
      margin: theme.spacing(1),
    },
  },
  textfield: {
    color: '#FFA911',
    backgroundColor: 'red',
  },
  submitButton: {
    color: 'white',
    '&:hover': {
      backgroundColor: '#f7a400',
    },
  },
}));

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#FFA911',
    },
  },
});

const errorMessages = {
  required: 'Ce champ est nécessaire.',
  minSize: n => `Au moins ${n} caractères`,
  maxSize: n => `Au plus ${n} caractères`,
  validEmail: 'Veuillez écrire une adresse mail valide.',
  usernameUnavailable: "Ce nom d'utilisateur n'est pas disponible.",
};

const SigninSchema = Yup.object().shape({
  username: Yup.string().ensure().required(errorMessages.required),
  password: Yup.string().required(errorMessages.required),
});

export default function SignIn() {
  const classes = useStyle();
  const { signin } = useAuth();
  const [showWarning, setShowWarning] = useState(false);

  const [successful, setSuccessful] = useState(false);

  const handleSubmit = ({ username, password }, { setErrors, setStatus }) => {
    signin(username, password)
      .then(() => {
        setSuccessful(true);
        notificationService.addNotification('Connexion réussie');
      })
      .catch(err => {
        const error = err.response.data;
        if (typeof error === 'string') {
          setStatus(error);
        } else {
          setErrors(err.response.data);
        }
      });
  };

  const formik = useFormik({
    initialValues: {
      username: '',
      password: '',
    },
    validationSchema: SigninSchema,
    onSubmit: handleSubmit,
  });

  if (successful) return <Redirect to='/' />;

  const error = field => field in formik.touched && field in formik.errors;
  const disabled = error('username') || error('email') || error('password');

  return (
    <Container maxWidth='xs'>
      <form
        className={classes.form}
        autoComplete='off'
        onSubmit={formik.handleSubmit}
        noValidate
      >
        <ThemeProvider theme={theme}>
          <Typography variant='h4' as='h1'>
            Se connecter
          </Typography>
          <TextField
            id='username'
            label="Nom d'utilisateur"
            name='username'
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.username}
            fullWidth
            required
            autoFocus
            autoComplete='username'
            error={'username' in formik.errors}
            helperText={
              'username' in formik.errors ? formik.errors.username : ''
            }
          />
          <PasswordField
            id='password'
            name='password'
            error={error('password')}
            helperText={error('password') ? formik.errors.password : ''}
            fullWidth
            required
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.password}
            autoComplete='current-password'
          />
          <Button
            className={classes.submitButton}
            variant='contained'
            fullWidth
            color='primary'
            type='submit'
            disabled={disabled}
          >
            Se connecter
          </Button>

          {formik.status && (
            <Typography variant='caption' style={{ color: 'red' }}>
              Nom d'utilisateur ou mot de passe incorrecte.
            </Typography>
          )}

          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'end',
              gap: 4,
              margin: -8,
              marginTop: 8,
            }}
          >
            <Link component={RouterLink} to='/signup'>
              Tu n'as pas de compte?
            </Link>

            {/*  eslint-disable-next-line jsx-a11y/anchor-is-valid */}
            <Link
              color='primary'
              onClick={() => setShowWarning(x => !x)}
              to='#'
            >
              Mot de passe oublié ?
            </Link>
            {showWarning && (
              <Typography color='textSecondary' variant='caption' align='right'>
                En cas de mot de passe oublié, contactez un membre d'
                <Link href='mailto:automatants@gmail.com'>Automatants</Link>. Il
                vous enverra un lien pour réinitialiser votre mot de passe.
              </Typography>
            )}
          </div>
        </ThemeProvider>
      </form>
    </Container>
  );
}
