import React from 'react';
import PropTypes from 'prop-types';

import {
  Typography,
  Box,
  Table,
  TableContainer,
  TableHead,
  TableBody,
  TableCell,
  TableRow,
} from '@material-ui/core';

export function ScoresDashboard({ scores, competitionType }) {
  return (
    <Box>
      <Typography variant='h4'>Tableau des scores</Typography>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Equipe</TableCell>
              <TableCell>Score</TableCell>
              <TableCell>
                {competitionType === 'score'
                  ? 'Nombres de soumissions'
                  : 'Nom de la soumission'}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {competitionType === 'score' &&
              scores.map(({ maxScore, nbSubmissions, teamId, teamName }) => (
                <TableRow key={teamId}>
                  <TableCell>{teamName}</TableCell>
                  <TableCell>{maxScore}</TableCell>
                  <TableCell>{nbSubmissions}</TableCell>
                </TableRow>
              ))}
            {competitionType === 'model' &&
              scores.map(({ score, name, teamId, teamName, membersName }) => (
                <TableRow key={teamId}>
                  <TableCell>
                    {teamName}
                    <br />
                    <Typography variant='caption' color='textSecondary'>
                      {membersName.join(', ')}
                    </Typography>
                  </TableCell>
                  <TableCell>{score}</TableCell>
                  <TableCell>{name}</TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
}

ScoresDashboard.propTypes = {
  scores: PropTypes.arrayOf(
    PropTypes.shape({
      maxScore: PropTypes.number,
      nSubmissions: PropTypes.number,
      name: PropTypes.string,
    })
  ).isRequired,
  competitionType: PropTypes.oneOf(['score', 'model']).isRequired,
};
