import React, { useState, useEffect } from 'react';
import Markdown from 'markdown-to-jsx';
import { Link as RouterLink, useParams, Redirect } from 'react-router-dom';

import { Container, Typography, Divider, Box, Link } from '@material-ui/core';

import { competitionsService } from '../../services';
import { Restricted } from '../../components';
import { ScoresDashboard } from './ScoresDashboard';
import { MyTeamDashboard } from './MyTeamDashboard/MyTeamDashboard';

export function Competition() {
  const { id } = useParams();
  const [loading, setLoading] = useState(true);
  const [competition, setCompetition] = useState({});
  const [failure, setFailure] = useState(false);
  const [scores, setScores] = useState([]);

  useEffect(() => {
    competitionsService.getCompetitionScores(id).then(setScores);
  }, [id]);

  useEffect(() => {
    setLoading(true);
    competitionsService.get(id).then(data => {
      if (!data) {
        setFailure(true);
        return;
      }

      const { start, end, ...rest } = data;

      setCompetition({ start: new Date(start), end: new Date(end), ...rest });
      setLoading(false);
    });
  }, [id]);

  if (failure) return <Redirect to='/compete' />;
  if (loading) return 'Loading';

  let state;
  const now = Date.now();

  if (now < competition.start) state = 'incoming';
  else if (now > competition.end) state = 'finished';
  else state = 'ongoing';

  return (
    <Container>
      <Typography variant='h1'>{competition.title}</Typography>
      <Typography variant='subtitle1'>
        <b>Début :</b> {competition.start.toLocaleString()}
      </Typography>
      <Typography variant='subtitle1'>
        <b>Fin :</b> {competition.end.toLocaleString()}
      </Typography>
      <Markdown>{competition.description}</Markdown>

      <Divider />
      <br />

      <Restricted unconnectedOnly>
        <Box>
          <Typography variant='h6'>
            <Link component={RouterLink} to='/signin'>
              Connecte toi
            </Link>{' '}
            pour rejoindre la compétition !
          </Typography>
        </Box>
      </Restricted>
      <Restricted>
        <MyTeamDashboard
          onSubmit={() =>
            competitionsService.getCompetitionScores(id).then(data => {
              setScores(data);
            })
          }
          state={state}
          competitionType={competition.type}
        />
      </Restricted>
      <br />
      <Divider />
      <ScoresDashboard scores={scores} competitionType={competition.type} />
    </Container>
  );
}
