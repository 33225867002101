import React, { useState } from 'react';

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
} from '@material-ui/core';
import { createTeam } from '../../../services/team.api';

export function CreateTeamDialog({ competitionId, onTeamCreated, ...props }) {
  const [teamName, setTeamName] = useState('');
  const handleTeamNameChange = e => {
    setTeamName(e.target.value);
  };
  const [errors, setErrors] = useState(null);

  const handleSubmit = () => {
    if (teamName.trim().length === 0) {
      setErrors({ name: "Il faut préciser un nom d'équipe." });
      return;
    }

    createTeam(teamName.trim(), competitionId)
      .then(onTeamCreated)
      .catch(error => {
        if (error.response && error.response.status === 400) {
          setErrors(error.response.data);
        } else {
          throw error;
        }
      });
  };

  return (
    <Dialog {...props}>
      <DialogTitle>Création équipe</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Afin de créer une équipe, veuillez trouver un nom !
        </DialogContentText>
        <TextField
          id='team-name'
          name='team-name'
          label="Nom d'équipe"
          fullWidth
          autoFocus
          value={teamName}
          onChange={handleTeamNameChange}
          error={!!errors}
          helperText={errors ? errors.name : ''}
        />
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            props.onClose();
          }}
        >
          Annuler
        </Button>
        <Button
          onClick={() => {
            handleSubmit();
          }}
        >
          Créer
        </Button>
      </DialogActions>
    </Dialog>
  );
}
