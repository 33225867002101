import React, { useEffect, useState } from 'react';
import {
  Button,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Typography,
} from '@material-ui/core';
import { Delete } from '@material-ui/icons';

import fileDownload from 'js-file-download';

import { competitionsService, notificationService } from '../../../../services';
import { deleteTeam, getAllTeams } from '../../../../services/team.api';
import {
  ButtonWithConfirmation,
  IconButtonWithConfirmation,
} from '../../../../components/ButtonWithConfirmation';
import { CompetitionSubmitDialog } from './CompetitionSubmitDialog';
import { EditCompetitionDialog } from './EditCompetitionDialog';
import { UpdateCompetitionContextButtons } from './UpdateCompetitionContextButton';

export function CompetitionAdmin({ competition, onDelete, onEdit }) {
  const [teams, setTeams] = useState(null);

  useEffect(() => {
    getAllTeams(competition.id).then(data => setTeams(data));
  }, [competition.id]);

  const removeTeam = id => {
    deleteTeam(id)
      .then(() => {
        notificationService.addNotification(
          'Cette équipe a été supprimé avec succès.'
        );
        setTeams(prevTeams => prevTeams.filter(team => team.id !== id));
      })
      .catch(error => {
        console.error(error);
        notificationService.addNotification("Une erreur s'est produite.");
      });
  };

  const [submitDialogOpen, setSubmitDialogOpen] = useState(false);
  const [editDialogOpen, setEditDialogOpen] = useState(false);

  return (
    <>
      {competition.type === 'model' && (
        <>
          <Typography>
            Récupérer un fichier zip avec les modèles, ainsi qu'un fichier
            summary.csv
          </Typography>
          <Typography>
            En python, pour décoder le nom des équipes et des modèles, vous
            pouvez utiliser <code>from urllib.parse import unquote</code>
          </Typography>

          <Button
            variant='contained'
            color='primary'
            onClick={() => {
              competitionsService.downloadModels(competition.id).then(res => {
                fileDownload(res.data, `models.zip`);
              });
            }}
          >
            Télécharger les modèles
          </Button>
        </>
      )}

      <Button variant='outlined' onClick={() => setSubmitDialogOpen(true)}>
        Blank submit
      </Button>
      <CompetitionSubmitDialog
        competitionId={competition.id}
        open={submitDialogOpen}
        onClose={() => setSubmitDialogOpen(false)}
      />

      <ButtonWithConfirmation
        variant='outlined'
        color='primary'
        onClick={onDelete}
        dialogProps={{
          title: `Voulez-vous supprimer la compétition '${competition.title}' ?`,
          body: 'Cette suppression est permanente. Les données seront perdues.',
          confirmText: 'Oui, la supprimer !',
          cancelText: 'Non, revenir en arrière',
        }}
      >
        Supprimer
      </ButtonWithConfirmation>

      <Button variant='outlined' onClick={() => setEditDialogOpen(true)}>
        Modifier
      </Button>
      <EditCompetitionDialog
        open={editDialogOpen}
        onClose={() => setEditDialogOpen(false)}
        onEdit={onEdit}
        competition={competition}
      />

      <UpdateCompetitionContextButtons
        competitionId={competition.id}
        onEdit={onEdit}
      />

      {teams && (
        <List>
          {teams.map(({ name, id, members }, i) => (
            <ListItem button key={id}>
              <ListItemIcon>
                <IconButtonWithConfirmation
                  onClick={() => {
                    removeTeam(id);
                  }}
                  dialogProps={{
                    title: `Voulez-vous supprimer l'équipe "${name}" ?`,
                    body: (
                      <>
                        Cette action est permamente. Toutes les données à propos
                        de leurs soumissions seront perdues.
                        <br />
                        <br />
                        Les membres de cette équipe pourront créer ou rejoindre
                        une nouvelle équipe.
                      </>
                    ),
                    confirmText: 'Supprimer',
                    cancelText: 'Annuler',
                  }}
                >
                  <Delete />
                </IconButtonWithConfirmation>
              </ListItemIcon>
              <ListItemText
                primary={name}
                secondary={members.map(({ username }) => username).join(', ')}
              />
            </ListItem>
          ))}
        </List>
      )}
    </>
  );
}
