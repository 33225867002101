import React, { useState } from 'react';
import { Container, Tab, Tabs } from '@material-ui/core';
import PropTypes from 'prop-types';

import { CompetitionAdminDashboard } from './CompetitionAdminDashboard';
import { UsersAdminDashboard } from './UsersAdminDashboard';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && children}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node.isRequired,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

export function AdminPanel() {
  const [selectedTab, setSelectedTab] = useState(0);

  return (
    <Container>
      <Tabs
        value={selectedTab}
        onChange={(event, newValue) => setSelectedTab(newValue)}
      >
        <Tab label='Utilisateurs' />
        <Tab label='Compétitions' />
      </Tabs>

      <TabPanel index={0} value={selectedTab}>
        <UsersAdminDashboard />
      </TabPanel>

      <TabPanel index={1} value={selectedTab}>
        <CompetitionAdminDashboard />
      </TabPanel>
    </Container>
  );
}
