import { createMuiTheme } from '@material-ui/core/styles';

export const mainTheme = createMuiTheme({
  palette: {
    primary: {
      main: '#FFA911',
    },
    secondary: {
      main: 'rgb(0, 0, 0, 0.42)',
    },
  },
});
