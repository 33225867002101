import React, { useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';

import {
  Container,
  Paper,
  Typography,
  Grid,
  makeStyles,
  withStyles,
  Divider,
  Button,
  Toolbar,
  TextField,
} from '@material-ui/core';
import { ThemeProvider } from '@material-ui/core/styles';

import { notificationService } from '../../services';
import { useAuth } from '../../contexts/auth';
import { mainTheme } from './theme';
import { EditPasswordField } from './EditPasswordField';

const useStyles = makeStyles(theme => ({
  container: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  disabledTextField: {
    color: 'black',
  },
  containedButton: {
    color: 'white',
    '&:hover': {
      backgroundColor: '#f7a400',
    },
  },
}));

const ProfileTextField = withStyles({
  root: {
    '& input.Mui-disabled': {
      color: 'black',
    },
    '& .Mui-disabled::before ': {
      border: 'none',
    },
  },
})(TextField);

const errorMessages = {
  required: 'Ce champ est nécessaire.',
  minSize: n => `Au moins ${n} caractères`,
  maxSize: n => `Au plus ${n} caractères`,
  validEmail: 'Veuillez écrire une adresse mail valide.',
  usernameUnavailable: "Ce nom d'utilisateur n'est pas disponible.",
};

export function Profile() {
  const classes = useStyles();
  const { currentUser, updateUser } = useAuth();
  const [editing, setEditing] = useState(false);

  const ProfileSchema = Yup.object().shape({
    username: Yup.string().required(errorMessages.required).ensure(),
  });

  const handleEditButton = () => {
    setEditing(true);
  };

  const handleSubmit = (values, { setErrors }) => {
    updateUser(currentUser.id, values)
      .then(() => {
        setEditing(false);
        notificationService.addNotification(
          'Les valeurs ont bien été modifiées.'
        );
      })
      .catch(e => {
        if (e.response && typeof e.response.data === 'object') {
          setErrors(e.response.data);
        } else {
          notificationService.addNotification("Une erreur s'est produite.");
        }
      });
  };

  const formik = useFormik({
    initialValues: {
      username: currentUser.username,
    },
    validationSchema: ProfileSchema,
    onSubmit: handleSubmit,
  });

  const handleCancel = () => {
    formik.resetForm();
    setEditing(false);
  };

  return (
    <Container className={classes.container} component={Paper}>
      <ThemeProvider theme={mainTheme}>
        <Toolbar>
          <Grid
            container
            spacing={2}
            alignItems='flex-end'
            justify='space-between'
          >
            <Grid item>
              <Typography variant='h4' component='h1'>
                Mon profil
              </Typography>
            </Grid>
            <Grid item>
              {!editing ? (
                <Button onClick={handleEditButton}>Éditer</Button>
              ) : (
                <>
                  <Button color='secondary' onClick={handleCancel}>
                    Annuler
                  </Button>
                  <Button
                    color='primary'
                    onClick={formik.handleSubmit}
                    variant='outlined'
                  >
                    Enregistrer
                  </Button>
                </>
              )}
            </Grid>
          </Grid>
        </Toolbar>

        <Container>
          <Typography variant='h6' component='h2'>
            Mes informations
          </Typography>
          <Divider style={{ marginBottom: '1em' }} />

          <form action='#' onSubmit={formik.handleSubmit}>
            <ProfileTextField
              id='username'
              label="Nom d'utilisateur"
              name='username'
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.username}
              fullWidth
              disabled={!editing}
              autoFocus
              autoComplete='username'
              error={'username' in formik.errors}
              helperText={editing ? formik.errors.username : ''}
            />
          </form>

          <EditPasswordField />
        </Container>
      </ThemeProvider>
    </Container>
  );
}
