import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';

import {
  Container,
  Typography,
  Grid,
  Divider,
  Box,
  Button,
  TextField,
  Table,
  TableContainer,
  TableHead,
  TableBody,
  TableCell,
  TableRow,
  AppBar,
  Tabs,
  Tab,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  CircularProgress,
} from '@material-ui/core';
import { Formik, Field, Form } from 'formik';
import * as Yup from 'yup';
import { formatDistance } from 'date-fns';
import { fr } from 'date-fns/locale';

import { competitionsService, notificationService } from '../../../services';
import {
  addTeamMember,
  getMyTeam,
  getMyTeamScores,
} from '../../../services/team.api';
import { CreateTeamDialog } from './CreateTeamDialog';

import { useAuth } from '../../../contexts/auth';

const TabPanel = ({ children, value, index, ...other }) => {
  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
    >
      {value === index && (
        <Box p={3} {...other}>
          {children}
        </Box>
      )}
    </div>
  );
};

TabPanel.propTypes = {
  children: PropTypes.node.isRequired,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

export function MyTeamDashboard({ onSubmit, state, competitionType }) {
  const { id } = useParams();
  const competitionId = Number(id);

  const [team, setTeam] = useState(null);
  const [open, setOpen] = useState(false);
  const [teamScores, setTeamScores] = useState([]);
  const [value, setValue] = useState(0);

  const { currentUser } = useAuth();

  const addMemberSchema = Yup.object().shape({
    username: Yup.string().required('Ce champ est requis.').ensure(),
  });

  useEffect(() => {
    getMyTeam(competitionId).then(data => {
      if (!data) return;

      setTeam(data);
    });
  }, [competitionId]);

  useEffect(() => {
    if (!team) return;

    getMyTeamScores(team.id).then(data => {
      setTeamScores(data);
    });
  }, [team]);

  const handleTabChange = (e, newValue) => {
    setValue(newValue);
  };

  const uploadPredictions = (values, actions) => {
    return competitionsService
      .submitPredictions(competitionId, values.file)
      .then(score => {
        notificationService.addNotification(`Score: ${score.value}`);
        setTeamScores(prevScores => [score, ...prevScores]);

        onSubmit();
        actions.resetForm();
      })

      .catch(() => {
        actions.setStatus(
          "Une erreur s'est produite lors de la soumission. Veuillez vérifier que votre soumission est au bon format. Si l'erreur persiste, n'hésitez pas à contacter l'administrateur de la plateforme."
        );
      });
  };

  const uploadModel = (values, actions) => {
    return competitionsService
      .submitModel(competitionId, values.file, values.name)
      .then(score => {
        notificationService.addNotification(`Score: ${score.value}`);
        setTeamScores(prevScores => [score, ...prevScores]);
        setTeam(prevTeam => ({ ...prevTeam, selectedModelId: score.id }));

        onSubmit();
        actions.resetForm();
      })

      .catch(() => {
        actions.setStatus(
          "Une erreur s'est produite lors de la soumission. Veuillez vérifier que votre soumission est au bon format. Si l'erreur persiste, n'hésitez pas à contacter l'administrateur de la plateforme."
        );
      });
  };

  const a11yProps = i => {
    return {
      id: `simple-tab-${i}`,
      'aria-controls': `simple-tabpanel-${i}`,
    };
  };

  if (!team && state === 'finished') return null;
  if (!team && state !== 'finished')
    return (
      <Box>
        <Typography variant='body1'>
          Tu ne fais partie d'aucune équipe pour l'instant. Demande à quelqu'un
          de t'ajouter à son équipe ou crée la tienne.
        </Typography>
        <Button
          variant='contained'
          onClick={() => {
            setOpen(true);
          }}
        >
          Créer mon équipe
        </Button>
        <CreateTeamDialog
          open={open}
          onClose={() => {
            setOpen(false);
          }}
          competitionId={competitionId}
          onTeamCreated={data => {
            setTeam(data);
            setOpen(false);
          }}
        />
      </Box>
    );

  return (
    <Box>
      <Typography variant='h4'>Equipe : {team.name}</Typography>

      <AppBar position='static'>
        <Tabs value={value} onChange={handleTabChange} aria-label='team tabs'>
          <Tab label='Membres' {...a11yProps(0)} />
          <Tab label='Soumissions' {...a11yProps(1)} />
        </Tabs>
      </AppBar>

      <TabPanel value={value} index={0}>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Nom d'utilisateur</TableCell>
                <TableCell>Adresse mail</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {team.members.map(({ id: userId, username, email }) => (
                <TableRow key={userId}>
                  <TableCell>{username}</TableCell>
                  <TableCell>{email}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>

        {state !== 'finished' && currentUser.id === team.leaderId ? (
          <Container>
            <Formik
              initialValues={{ username: '' }}
              onSubmit={(values, actions) => {
                return addTeamMember(team.id, competitionId, values.username)
                  .then(data => {
                    setTeam(data);
                    actions.resetForm();
                  })
                  .catch(error => {
                    if (error.response && error.response.status === 400) {
                      actions.setErrors(error.response.data);
                    }
                  });
              }}
              validationSchema={addMemberSchema}
            >
              <Form>
                <Grid container spacing={3}>
                  <Grid item xs={9}>
                    <Field name='username'>
                      {({ field, meta }) => (
                        <TextField
                          label="Nom d'utilisateur"
                          error={meta.error != null && meta.touched}
                          helperText={meta.error ? meta.error : ''}
                          fullWidth
                          {...field}
                        />
                      )}
                    </Field>
                  </Grid>
                  <Grid item xs={3}>
                    <Button variant='contained' type='submit' fullWidth>
                      Ajouter
                    </Button>
                  </Grid>
                </Grid>
              </Form>
            </Formik>
          </Container>
        ) : null}
      </TabPanel>

      <TabPanel value={value} index={1}>
        {state === 'ongoing' && (
          <>
            {competitionType === 'model' && teamScores.length > 0 && (
              <>
                <Typography variant='h6'>Soumission sélectionnée</Typography>
                <Formik
                  onSubmit={values => {
                    competitionsService
                      .updateSelectedModel(team.id, values.selectedModelId)
                      .then(newTeam => {
                        setTeam(newTeam);
                        onSubmit();
                        notificationService.addNotification(
                          'Votre choix a été enregistré.'
                        );
                      });
                  }}
                  enableReinitialize
                  initialValues={{
                    selectedModelId: team.selectedModelId || '',
                  }}
                  validationSchema={Yup.object().shape({
                    selectedModelId: Yup.number().required(),
                  })}
                >
                  {({ handleSubmit, values }) => (
                    <form onSubmit={handleSubmit}>
                      <Grid container spacing={3}>
                        <Grid item xs={6}>
                          <Field name='selectedModelId'>
                            {({ field }) => (
                              <FormControl fullWidth>
                                <InputLabel>Soumission sélectionnée</InputLabel>
                                <Select {...field}>
                                  {teamScores.map(
                                    ({
                                      id: scoreId,
                                      name,
                                      value: scoreValue,
                                    }) => (
                                      <MenuItem value={scoreId}>
                                        {name} (Score : {scoreValue})
                                      </MenuItem>
                                    )
                                  )}
                                </Select>
                              </FormControl>
                            )}
                          </Field>
                        </Grid>
                        <Grid item xs={6}>
                          {values.selectedModelId !== team.selectedModelId && (
                            <Button fullWidth type='submit' variant='contained'>
                              Changer la soumission sélectionnée
                            </Button>
                          )}
                        </Grid>
                      </Grid>
                    </form>
                  )}
                </Formik>

                <br />
                <Divider />
              </>
            )}

            <Typography variant='h6'>Nouvelle soumission</Typography>
            <Formik
              onSubmit={
                competitionType === 'score' ? uploadPredictions : uploadModel
              }
              initialValues={{ name: '' }}
              validationSchema={Yup.object().shape({
                file: Yup.mixed().required(),
                name:
                  competitionType === 'score'
                    ? null
                    : Yup.string().trim().required(),
              })}
            >
              {({
                handleSubmit,
                values,
                setFieldValue,
                handleReset,
                isSubmitting,
                status,
              }) => (
                <form onSubmit={handleSubmit}>
                  <Grid container spacing={3}>
                    {!values.file ? (
                      <Grid item xs={12}>
                        <Button fullWidth variant='outlined' component='label'>
                          Téléverser des résultats.
                          <input
                            type='file'
                            onChange={e => {
                              setFieldValue('file', e.target.files[0]);
                            }}
                            style={{ display: 'none' }}
                          />
                        </Button>
                      </Grid>
                    ) : (
                      <>
                        <Grid item xs={6}>
                          <Button
                            fullWidth
                            variant='outlined'
                            component='label'
                            style={{ textTransform: 'none' }}
                          >
                            {values.file.name} (
                            {(values.file.size / 1000).toFixed(0)} ko)
                            <input
                              type='file'
                              onChange={e => {
                                setFieldValue('file', e.target.files[0]);
                              }}
                              style={{ display: 'none' }}
                            />
                          </Button>
                        </Grid>
                        <Grid item xs={3}>
                          <Button fullWidth onClick={handleReset}>
                            Annuler
                          </Button>
                        </Grid>
                        {competitionType === 'score' ? (
                          <Grid item xs={3}>
                            <Button fullWidth type='submit' variant='contained'>
                              Envoyer le fichier
                            </Button>
                          </Grid>
                        ) : (
                          <>
                            <Grid item xs={9}>
                              <Field name='name'>
                                {({ field, meta }) => (
                                  <TextField
                                    label='Nom de la soumission'
                                    error={meta.touched && meta.error != null}
                                    helperText={
                                      meta.touched && meta.error
                                        ? meta.error
                                        : ''
                                    }
                                    fullWidth
                                    {...field}
                                  />
                                )}
                              </Field>
                            </Grid>
                            <Grid item xs={3}>
                              {isSubmitting ? (
                                <CircularProgress />
                              ) : (
                                <Button
                                  variant='contained'
                                  type='submit'
                                  fullWidth
                                  disabled={isSubmitting}
                                >
                                  Ajouter
                                </Button>
                              )}
                            </Grid>
                            {status && (
                              <Grid item>
                                <Typography variant='body1' color='error'>
                                  {status}
                                </Typography>
                              </Grid>
                            )}
                          </>
                        )}
                      </>
                    )}
                  </Grid>
                </form>
              )}
            </Formik>

            <br />
            <Divider />
          </>
        )}

        <Typography variant='h6'>Historique des soumissions</Typography>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>
                  {competitionType === 'score' ? 'Tentative' : 'Nom'}
                </TableCell>
                <TableCell>Score</TableCell>
                <TableCell>Date</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {teamScores.map(
                ({ id: scoreId, value: scoreValue, createdAt, name }, i) => (
                  <TableRow key={scoreId}>
                    <TableCell
                      style={{
                        fontWeight: scoreId === team.SelectedModelId && 'bold',
                      }}
                    >
                      {competitionType === 'score'
                        ? teamScores.length - i
                        : name}
                    </TableCell>
                    <TableCell>{scoreValue}</TableCell>
                    <TableCell>
                      il y a{' '}
                      {formatDistance(new Date(createdAt), Date.now(), {
                        locale: fr,
                      })}
                    </TableCell>
                  </TableRow>
                )
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </TabPanel>
    </Box>
  );
}

MyTeamDashboard.propTypes = {
  setScores: PropTypes.func.isRequired,
  state: PropTypes.oneOf(['ongoing', 'incoming', 'finished']).isRequired,
  competitionType: PropTypes.oneOf(['score', 'model']).isRequired,
};
