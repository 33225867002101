import React, { useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';

import { Grid, makeStyles, Button } from '@material-ui/core';

import PasswordField from '../../components/PasswordField';
import { userService, notificationService } from '../../services';
import { useAuth } from '../../contexts/auth';

const useStyles = makeStyles(theme => ({
  container: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  disabledTextField: {
    color: 'black',
  },
  containedButton: {
    color: 'white',
    '&:hover': {
      backgroundColor: '#f7a400',
    },
  },
}));

const errorMessages = {
  required: 'Ce champ est nécessaire.',
  minSize: n => `Au moins ${n} caractères`,
  maxSize: n => `Au plus ${n} caractères`,
  validEmail: 'Veuillez écrire une adresse mail valide.',
  usernameUnavailable: "Ce nom d'utilisateur n'est pas disponible.",
};

const editPasswordSchema = Yup.object().shape({
  currentPassword: Yup.string().required(errorMessages.required),
  newPassword: Yup.string()
    .min(8, errorMessages.minSize(8))
    .required(errorMessages.required),
});

export function EditPasswordField() {
  const classes = useStyles();
  const { currentUser } = useAuth();
  const [editPassword, setEditPassword] = useState(false);

  const save = (values, { setErrors, resetForm }) => {
    userService
      .updatePassword(
        currentUser.id,
        values.currentPassword,
        values.newPassword
      )
      .then(() => {
        setEditPassword(false);
        notificationService.addNotification(
          'Votre mot de passe a bien été modifié.'
        );
        resetForm();
      })
      .catch(e => {
        if (typeof e.response.data === 'object') setErrors(e.response.data);
        else notificationService.addNotification("Une erreur s'est produite.");
      });
  };

  const formik = useFormik({
    initialValues: { currentPassword: '', newPassword: '' },
    validationSchema: editPasswordSchema,
    onSubmit: save,
  });

  const error = field => field in formik.touched && field in formik.errors;

  if (!editPassword)
    return (
      <Button
        onClick={() => {
          setEditPassword(true);
        }}
        style={{ margin: '1em 0' }}
        variant='contained'
      >
        Modifier mon mot de passe
      </Button>
    );

  return (
    <Grid container alignItems='center' style={{ margin: '1em 0' }} spacing={2}>
      <Grid item sm={4}>
        <PasswordField
          id='currentPassword'
          label='Mot de passe actuel'
          name='currentPassword'
          autoComplete='current-password'
          fullWidth
          required
          autoFocus
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.currentPassword}
          error={error('currentPassword')}
          helperText={
            error('currentPassword') ? formik.errors.currentPassword : ''
          }
        />
      </Grid>
      <Grid item sm={4}>
        <PasswordField
          id='newPassword'
          label='Nouveau mot de passe'
          name='newPassword'
          fullWidth
          required
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.newPassword}
          error={error('newPassword')}
          helperText={error('newPassword') ? formik.errors.newPassword : ''}
        />
      </Grid>
      <Grid item sm={4}>
        <Button
          className={classes.containedButton}
          variant='contained'
          color='primary'
          fullWidth
          type='submit'
          onClick={formik.handleSubmit}
        >
          Enregistrer
        </Button>
      </Grid>
    </Grid>
  );
}
