import React, { useState } from 'react';
import { Redirect, Link as RouterLink } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from 'yup';

import {
  Container,
  makeStyles,
  TextField,
  Button,
  Link,
  Typography,
} from '@material-ui/core';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';

import { notificationService } from '../services';
import { signup } from '../services/authentication';
import PasswordField from '../components/PasswordField';

const useStyle = makeStyles(theme => ({
  form: {
    marginTop: theme.spacing(3),
    '& > *': {
      margin: theme.spacing(1),
    },
  },
  textfield: {
    color: '#FFA911',
    backgroundColor: 'red',
  },
  rightAlign: {
    textAlign: 'right',
  },
  submitButton: {
    color: 'white',
    '&:hover': {
      backgroundColor: '#f7a400',
    },
  },
}));

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#FFA911',
    },
  },
});

const errorMessages = {
  required: 'Ce champ est nécessaire.',
  minSize: n => `Au moins ${n} caractères`,
  maxSize: n => `Au plus ${n} caractères`,
  validEmail: 'Veuillez écrire une adresse mail valide.',
  usernameUnavailable: "Ce nom d'utilisateur n'est pas disponible.",
};

const SignupSchema = Yup.object().shape({
  username: Yup.string()
    .required(errorMessages.required)
    .ensure()
    .min(3, errorMessages.minSize(3)),
  password: Yup.string()
    .min(8, errorMessages.minSize(8))
    .required(errorMessages.required),
});

export function SignUp() {
  const classes = useStyle();

  const [successful, setSuccessful] = useState(false);

  const handleSubmit = ({ username, password }, { setErrors }) => {
    signup(username, password)
      .then(() => {
        setSuccessful(true);
        notificationService.addNotification('Ton compte a bien été créé.');
      })
      .catch(e => {
        if (e.response) {
          setErrors(e.response.data);
        } else {
          console.error(e);
        }
      });
  };

  const formik = useFormik({
    initialValues: {
      username: '',
      password: '',
    },
    validationSchema: SignupSchema,
    onSubmit: handleSubmit,
  });

  if (successful) return <Redirect to='/signin' />;

  const error = field => field in formik.touched && field in formik.errors;
  const disabled = error('username') || error('email') || error('password');

  return (
    <Container maxWidth='xs'>
      <form
        className={classes.form}
        autoComplete='off'
        onSubmit={formik.handleSubmit}
        noValidate
      >
        <ThemeProvider theme={theme}>
          <Typography variant='h4' as='h1'>
            Créer un compte
          </Typography>
          <TextField
            id='username'
            label="Nom d'utilisateur"
            name='username'
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.username}
            fullWidth
            autoFocus
            required
            autoComplete='username'
            error={'username' in formik.errors}
            helperText={formik.errors.username}
          />
          <PasswordField
            id='password'
            error={error('password')}
            helperText={error('password') ? formik.errors.password : ''}
            fullWidth
            required
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.password}
          />
          <Button
            className={classes.submitButton}
            variant='contained'
            fullWidth
            color='primary'
            type='submit'
            disabled={disabled}
          >
            Créer son compte
          </Button>
          <div className={classes.rightAlign}>
            <Link component={RouterLink} to='/signin'>
              J'ai déjà un compte
            </Link>
          </div>
        </ThemeProvider>
      </form>
    </Container>
  );
}
