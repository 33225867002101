import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  useLocation,
  Link as RouterLink,
} from 'react-router-dom';

import {
  Typography,
  makeStyles,
  Container,
  Grid,
  Box,
  Link,
} from '@material-ui/core';
import { ThemeProvider } from '@material-ui/core/styles';
import { Profile } from './pages/Profile';
import { AdminPanel } from './pages/AdminPanel';
import { CompeteHome } from './pages/CompeteHome';
import { Competition } from './pages/Competition';
import { NavBar } from './display';
import { theme, GlobalCss, StrongOrange } from './display/Theme';
import { RestrictedRoute, SignIn } from './components';

import LearnHome from './learn';
import TrainHome from './train';
import { ResetPassword } from './pages/ResetPassword/ResetPassword';
import { SignUp } from './pages/SignUp';

export default function App() {
  const [blurred, setBlurred] = useState(false);

  return (
    <>
      <GlobalCss />
      <ThemeProvider theme={theme}>
        <Router>
          <div style={{ filter: blurred ? 'blur(4px)' : null }}>
            <NavBar setBlurred={setBlurred} />
            <Switch>
              <Route exact path='/'>
                <Home />
              </Route>
              <Route path='/signup'>
                <SignUp />
              </Route>
              <Route path='/signin'>
                <SignIn />
              </Route>
              <Route path='/about'>
                <About />
              </Route>
              <Route path='/learn'>
                <LearnHome />
              </Route>

              <Route path='/compete/:id'>
                <Competition />
              </Route>
              <Route path='/compete'>
                <CompeteHome />
              </Route>

              <Route path='/reinitiliser-mot-de-passe/:token'>
                <ResetPassword />
              </Route>

              <Route path='/train'>
                <TrainHome />
              </Route>
              <RestrictedRoute path='/profile'>
                <Profile />
              </RestrictedRoute>
              <RestrictedRoute requiredRole='admin'>
                <AdminPanel />
              </RestrictedRoute>
              <Route>
                <NotFound />
              </Route>
            </Switch>
          </div>
        </Router>
      </ThemeProvider>
    </>
  );
}

const useStyles = makeStyles(() => ({
  tile: {
    borderRadius: '8px',
    display: 'flex',
    height: '10em',
    backgroundColor: '#303030',
    transition: '0.3s ease',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    textDecoration: 'none',
    margin: '1em',
    transform: 'scale(1.000001)',
    '&:hover': {
      transform: 'scale(1.1)',
      backgroundColor: '#FFA911',
      '& p': {
        opacity: '0.99',
        maxHeight: '100em',
      },
      '& div': {
        opacity: '1',
      },
    },
    '& p,& h3': {
      color: 'white',
    },
    '& p': {
      transition: '0.3s ease',
      opacity: '0',
      maxHeight: '0',
      margin: '0',
    },
    '& h3': {
      margin: '0.5em',
      opacity: '0.99',
    },
    '& div': {
      position: 'absolute',
      borderRadius: '8px',
      bottom: '0',
      width: '100%',
      height: '100%',
      backgroundColor: '#FFA911',
      transition: '0.3s ease',
      opacity: '0',
    },
  },
}));

const Tile = ({ href, title, gradient, children }) => {
  const classes = useStyles();
  return (
    <Link
      component={RouterLink}
      to={href}
      className={classes.tile}
      style={{ backgroundImage: gradient }}
    >
      <div />
      <Typography variant='h3'>{title}</Typography>
      <Typography>{children}</Typography>
    </Link>
  );
};

Tile.propTypes = {
  href: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  gradient: PropTypes.string.isRequired,
  children: PropTypes.string.isRequired,
};

const Home = () => {
  // const classes = useStyles();

  return (
    <>
      <Container>
        <Box mt={12} mb={3}>
          <Typography variant='h1' color='primary' m={5}>
            Welcome to <StrongOrange>Sharing</StrongOrange> !
          </Typography>
        </Box>
        <Box mb={12}>
          <Typography variant='h5' color='primary'>
            A platform to discover, improve and excel in{' '}
            <StrongOrange>AI</StrongOrange>, made by{' '}
            <Link href='https://automatants.cs-campus.fr/'>Automatants</Link>.
          </Typography>
        </Box>
        <Grid container direction='row' justify='center' alignItems='center'>
          <Grid item xs={12} sm={6} md={4}>
            <Tile
              href='/learn'
              title='Learn'
              gradient='linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)'
            >
              That's where you start
            </Tile>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Tile
              href='#'
              title='Train'
              gradient='linear-gradient(to right top, #38eca5, #00c1c3, #0090d6, #005cc8, #00188c)'
            >
              That's where you start
            </Tile>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Tile
              href='/compete'
              title='Compete'
              gradient='linear-gradient(to right top, #f44f4f, #e62266, #cc007f, #a10097, #5d22aa)'
            >
              That's where you start
            </Tile>
          </Grid>
        </Grid>
        <Box mt={12}>
          <Typography variant='h5' color='primary'>
            Recent news...
          </Typography>
        </Box>
      </Container>
      <a href='/mockup/html/index.html'>Accès rapide au mockup</a>
    </>
  );
};

const About = () => {
  return <h1>About</h1>;
};

const NotFound = () => {
  const location = useLocation();

  return (
    <div>
      <h1>No match for {location.pathname}</h1>
    </div>
  );
};
