import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';

import { useAuth } from '../contexts/auth';

const RestrictedRoute = ({ requiredRole, children, ...rest }) => {
  const { currentUser } = useAuth();

  if (!currentUser)
    return (
      <Route {...rest}>
        <Redirect to='/signin' />
      </Route>
    );
  if (requiredRole && requiredRole !== currentUser.role)
    return (
      <Route {...rest}>
        <Redirect to='/' />
      </Route>
    );

  return <Route {...rest}>{children}</Route>;
};

RestrictedRoute.propTypes = {
  children: PropTypes.node.isRequired,
  requiredRole: PropTypes.string,
};

RestrictedRoute.defaultProps = {
  requiredRole: null,
};

export default RestrictedRoute;
