import React, { useState, useEffect } from 'react';

import { Snackbar, IconButton } from '@material-ui/core';
import { Close as CloseIcon } from '@material-ui/icons';

import { notificationService } from '../services';

const Notifications = () => {
  const [notification, setNotification] = useState(null);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    const sub = notificationService.currentNotification.subscribe(value => {
      setNotification(value);
      setOpen(true);
    });

    return () => {
      sub.unsubscribe();
    };
  }, []);

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') return;

    setOpen(false);
  };

  if (!notification) return null;
  return (
    <Snackbar
      open={open}
      message={notification}
      autoHideDuration={10000}
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      onClose={handleClose}
      action={
        // eslint-disable-next-line
        <IconButton
          size='small'
          color='inherit'
          aria-label='close'
          onClick={handleClose}
        >
          <CloseIcon fontSize='small' />
        </IconButton>
      }
    />
  );
};

export default Notifications;
