import { Subject } from 'rxjs';

const notification = new Subject();

const addNotification = value => {
  notification.next(value);
};

export default {
  addNotification,
  currentNotification: notification.asObservable(),
  get currentNotificationValue() {
    return notification.value;
  },
};
