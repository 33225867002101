import axios from 'axios';

const getAll = () => {
  return axios.get('/api/courses');
};

const get = id => {
  return axios.get(`/api/courses/${id}`);
};

export default { getAll, get };
