import axios from 'axios';
import { authHeader } from '../helpers';
import { api, renewAccessToken } from './authentication';

// New stuff

function getAll(type) {
  return api
    .get('/api/competition', { params: { type } })
    .then(response => response.data);
}

function get(id) {
  return api.get(`/api/competition/${id}`).then(response => response.data);
}

async function createCompetition({
  title,
  description,
  start,
  end,
  context,
  type,
}) {
  const config = {
    headers: { 'content-type': 'multipart/form-data' },
    onUploadProgress(progressEvent) {
      const percentCompleted = Math.round(
        (progressEvent.loaded * 100) / progressEvent.total
      );
      console.log('Uploading file: ', percentCompleted);
    },
  };

  const formData = new FormData();
  formData.append('context', context);
  formData.append('title', title);
  formData.append('description', description);
  formData.append('start', start.toISOString());
  formData.append('end', end.toISOString());
  formData.append('type', type);

  return renewAccessToken().then(() =>
    api
      .post('/api/competition', formData, config)
      .then(response => response.data)
  );
}

export async function updateCompetitionPredictions(id, context) {
  const config = {
    headers: { 'content-type': 'multipart/form-data' },
  };

  const formData = new FormData();
  formData.append('context', context);

  return renewAccessToken().then(() =>
    api
      .post(`/api/competition/${id}/image`, formData, config)
      .then(response => response.data)
  );
}

async function removeCompetition(id) {
  return api.delete(`/api/competition/${id}`);
}

async function blankSubmit(competitionId, file) {
  const config = {
    headers: { 'content-type': 'multipart/form-data' },
    onUploadProgress(progressEvent) {
      const percentCompleted = Math.round(
        (progressEvent.loaded * 100) / progressEvent.total
      );
      console.log('Uploading file: ', percentCompleted);
    },
  };

  const formData = new FormData();
  formData.append('file', file);

  // Always renew the access token to prevent upload fail because of this
  return renewAccessToken().then(() =>
    api
      .post(`/api/competition/${competitionId}/blank-submit`, formData, config)
      .then(response => response.data)
  );
}

function getCompetitionScores(competitionId) {
  return api
    .get(`/api/competition/${competitionId}/scores`)
    .then(response => response.data);
}

function submitPredictions(competitionId, file) {
  const config = {
    headers: { 'content-type': 'multipart/form-data' },
  };

  const formData = new FormData();
  formData.append('score_file', file);

  return api
    .post(
      `/api/competition/${competitionId}/submit-predictions`,
      formData,
      config
    )
    .then(response => response.data);
}

function submitModel(competitionId, file, name) {
  const config = {
    headers: { 'content-type': 'multipart/form-data' },
  };

  const formData = new FormData();
  formData.append('score_file', file);
  formData.append('name', name);

  return renewAccessToken().then(() =>
    api
      .post(`/api/competition/${competitionId}/submit-model`, formData, config)
      .then(response => response.data)
  );
}

function updateSelectedModel(teamId, modelId) {
  return api
    .post(`/api/team/${teamId}/update-selected-model`, { modelId })
    .then(response => response.data);
}

function downloadModels(competitionId) {
  const config = {
    responseType: 'blob',
  };

  return api.get(`/api/competition/${competitionId}/download-models`, config);
}

export function updateCompetition(id, data) {
  return api
    .post(`/api/competition/${id}`, data)
    .then(response => response.data);
}

// Old stuff

// const getTeams = competitionId => {
//   const config = {
//     headers: authHeader(),
//   };

//   return axios
//     .get(`/api/competitions/${competitionId}/teams`, config)
//     .then(res => {
//       return res.data;
//     });
// };

const removeTeam = id => {
  const config = {
    headers: authHeader(),
  };

  return axios.get(`/api/competitions/team/${id}/delete`, config).then(res => {
    return res.data;
  });
};

const hasTeam = (competitionId, username) => {
  const config = {
    headers: authHeader(),
  };

  return axios
    .get(`/api/competitions/${competitionId}/team/${username}`, config)
    .then(res => {
      return res.data;
    });
};

const getTeamScores = teamId => {
  const config = {
    headers: authHeader(),
  };

  return axios
    .get(`/api/competitions/team/${teamId}/scores`, config)
    .then(res => {
      return res.data;
    });
};

const isTeamNameAvailable = async (competitionId, name) => {
  try {
    await axios.post(`/api/competitions/${competitionId}/team-name-available`, {
      name,
    });
    return true;
  } catch (e) {
    return false;
  }
};

const addTeam = async (competitionId, name) => {
  const config = {
    headers: authHeader(),
  };

  return axios
    .post(`/api/competitions/${competitionId}/team`, { name }, config)
    .then(res => {
      return res.data;
    });
};

// const removeCompetition = competitionId => {
//   const config = {
//     headers: authHeader(),
//     timeout: 1000,
//   };

//   return axios
//     .delete(`/api/competitions/${competitionId}`, config)
//     .then(res => {
//       return res.data;
//     })
//     .catch(console.log);
// };

export default {
  createCompetition,
  getAll,
  get,
  isTeamNameAvailable,
  addTeam,
  hasTeam,
  getCompetitionScores,
  submitPredictions,
  submitModel,
  updateCompetitionPredictions,
  // getTeams,
  removeTeam,
  getTeamScores,
  updateSelectedModel,
  downloadModels,
  removeCompetition,
  updateCompetition,
  blankSubmit,
};
