import React, { useEffect, useState } from 'react';
import {
  Table,
  TableContainer,
  TableHead,
  TableBody,
  TableCell,
  TableRow,
  Typography,
  Checkbox,
  Button,
} from '@material-ui/core';

import { notificationService, userService } from '../../services';
import { useAuth } from '../../contexts/auth';

export function UsersAdminDashboard() {
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);

  const { currentUser } = useAuth();

  useEffect(() => {
    userService
      .getAllUsers()
      .then(data => {
        setUsers(data);
        setLoading(false);
      })
      .catch(console.log);
  }, []);

  const handleUserRoleChange = (userId, role) => () => {
    userService
      .updateUserRole(userId, role === 'admin' ? '' : 'admin')
      .then(updatedUser => {
        setUsers(prevUsers =>
          prevUsers.map(user => (user.id === userId ? updatedUser : user))
        );
        notificationService.addNotification(
          'Modification effectuée avec succès!'
        );
      })
      .catch(error => {
        if (!error.response || error.response.status !== 400) {
          console.error(error);
          notificationService.addNotification(
            "Une erreur s'est produite. Veuillez réessayer plus tard."
          );
          return;
        }

        notificationService.addNotification(`Erreur : ${error.response.data}`);
      });
  };

  const handleGenerateResetPasswordLink = userId => () => {
    userService.generateResetPasswordToken(userId).then(({ token }) => {
      setUsers(prevUsers =>
        prevUsers.map(user =>
          user.id === userId ? { ...user, resetPasswordToken: token } : user
        )
      );
    });
  };

  if (loading) return 'loading';

  return (
    <div>
      <Typography variant='h2'>Utilisateurs</Typography>

      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>id</TableCell>
              <TableCell>Admin</TableCell>
              <TableCell>Nom d'utilisateur</TableCell>
              <TableCell>Adresse mail</TableCell>
              <TableCell>Date de création</TableCell>
              <TableCell>Mot de passe oublié</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {users.map(
              ({
                id,
                username,
                email,
                createdAt,
                role,
                resetPasswordToken,
              }) => (
                <TableRow key={id}>
                  <TableCell>#{id}</TableCell>
                  <TableCell>
                    <Checkbox
                      disabled={currentUser.id === id}
                      checked={role === 'admin'}
                      onChange={handleUserRoleChange(id, role)}
                      color='primary'
                    />
                  </TableCell>
                  <TableCell>{username}</TableCell>
                  <TableCell>{email}</TableCell>
                  <TableCell>{new Date(createdAt).toLocaleString()}</TableCell>
                  <TableCell>
                    {resetPasswordToken && (
                      <code>
                        {window.location.origin}/reinitiliser-mot-de-passe/
                        {resetPasswordToken}
                      </code>
                    )}
                    <Button
                      variant='text'
                      size='small'
                      style={{ textTransform: 'initial' }}
                      onClick={handleGenerateResetPasswordLink(id)}
                    >
                      Générer un lien en cas de mot de passe oublié
                    </Button>
                  </TableCell>
                </TableRow>
              )
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}
