import React from 'react';
import {
  TextField,
  Button,
  Grid,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
} from '@material-ui/core';
import { MuiPickersUtilsProvider, DateTimePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import Markdown from 'markdown-to-jsx';

import { Formik, Field, Form } from 'formik';
import * as yup from 'yup';
import { updateCompetition } from '../../../../services/competitions';

const schema = yup.object().shape({
  title: yup.string().required(),
  description: yup.string().required(),
  start: yup.date().required(),
  end: yup.date().required(),
});

export function EditCompetitionDialog({ competition, onEdit, ...props }) {
  const handleSubmit = async (values, actions) => {
    return updateCompetition(competition.id, values).then(
      updatedCompetition => {
        onEdit(updatedCompetition);
        props.onClose();
        actions.resetForm();
      }
    );
  };

  return (
    <Dialog {...props} maxWidth='lg' fullWidth>
      <DialogTitle>Modifier une compétition</DialogTitle>

      <Formik
        enableReinitialize
        initialValues={competition}
        onSubmit={handleSubmit}
        validationSchema={schema}
      >
        {({ values, setFieldValue, resetForm }) => (
          <Form>
            <DialogContent style={{ paddingTop: 0 }}>
              <Field name='title'>
                {({ field, meta }) => (
                  <TextField
                    label='Titre de la compétition'
                    {...field}
                    fullWidth
                    required
                    autoFocus
                    error={meta.touched && meta.error != null}
                    helperText={meta.touched && meta.error ? meta.error : ''}
                    style={{ marginBottom: 24 }}
                  />
                )}
              </Field>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <Field name='description'>
                    {({ field, meta }) => (
                      <TextField
                        label='Description de la compétition'
                        {...field}
                        fullWidth
                        required
                        variant='outlined'
                        multiline
                        rows={10}
                        error={meta.touched && meta.error != null}
                        helperText={
                          meta.touched && meta.error ? meta.error : ''
                        }
                      />
                    )}
                  </Field>
                </Grid>

                <Grid item xs={6}>
                  <Markdown>{values.description}</Markdown>
                </Grid>
              </Grid>

              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <Grid container spacing={2}>
                  <Grid item md={6}>
                    <DateTimePicker
                      fullWidth
                      name='start'
                      value={values.start}
                      label='Début de la compétition'
                      onChange={date => {
                        if (date > values.end) setFieldValue('end', date);
                        setFieldValue('start', date);
                      }}
                    />
                  </Grid>
                  <Grid item md={6}>
                    <DateTimePicker
                      fullWidth
                      name='end'
                      value={values.end}
                      label='Fin de la compétition'
                      onChange={date => {
                        if (date < values.start) setFieldValue('start', date);
                        setFieldValue('end', date);
                      }}
                    />
                  </Grid>
                </Grid>
              </MuiPickersUtilsProvider>
            </DialogContent>

            <DialogActions>
              <Button
                onClick={() => {
                  resetForm();
                  props.onClose();
                }}
              >
                Revenir en arrière
              </Button>
              <Button type='submit' variant='contained' color='primary'>
                Modifier
              </Button>
            </DialogActions>
          </Form>
        )}
      </Formik>
    </Dialog>
  );
}
