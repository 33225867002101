import React, { useEffect, useState, useCallback } from 'react';
import { Typography, Button } from '@material-ui/core';

import { competitionsService, notificationService } from '../../../services';
import { CompetitionsList } from './CompetitionsList';
import { CreateCompetitionDialog } from './CreateCompetitionDialog/CreateCompetitionDialog';
import { CompetitionAdmin } from './CompetitionAdmin/CompetitionAdmin';

function transformCompetition(competition) {
  return {
    ...competition,
    start: new Date(competition.start),
    end: new Date(competition.end),
  };
}

export const CompetitionAdminDashboard = () => {
  const [competitions, setCompetitions] = useState([]);
  const [selectedCompetitionId, setSelectedCompetitionId] = useState(null);

  useEffect(() => {
    competitionsService.getAll().then(data => {
      setCompetitions(
        data.map(competition => transformCompetition(competition))
      );
    });
  }, []);

  const onSelectCompetition = useCallback(id => {
    setSelectedCompetitionId(id);
  }, []);

  const selectedCompetition = competitions.find(
    competition => competition.id === selectedCompetitionId
  );

  const [openCreateDialog, setOpenCreateDialog] = useState(false);

  const onDeleteCompetition = id => {
    competitionsService
      .removeCompetition(id)
      .then(() => {
        setSelectedCompetitionId(null);
        notificationService.addNotification(
          `Competition #${id} supprimée avec succès.`
        );
        setCompetitions(prevCompetitions =>
          prevCompetitions.filter(competition => competition.id !== id)
        );
      })
      .catch(error => {
        notificationService.addNotification("Une erreur s'est produite.");
        console.error(error);
      });
  };

  const onEditCompetition = (id, data) => {
    setCompetitions(prevCompetitions =>
      prevCompetitions.map(competition =>
        competition.id === id ? transformCompetition(data) : competition
      )
    );
  };

  return (
    <>
      <Typography variant='h2'>Compétitions</Typography>

      <Button variant='contained' onClick={() => setOpenCreateDialog(true)}>
        Créer une nouvelle compétition
      </Button>

      <CreateCompetitionDialog
        open={openCreateDialog}
        onClose={() => setOpenCreateDialog(false)}
        onAdd={competition => {
          setOpenCreateDialog(false);
          setCompetitions(prevCompetitions => [
            transformCompetition(competition),
            ...prevCompetitions,
          ]);
        }}
      />

      <CompetitionsList
        competitions={competitions}
        onSelect={onSelectCompetition}
      />

      {selectedCompetition && (
        <CompetitionAdmin
          competition={selectedCompetition}
          onDelete={() => onDeleteCompetition(selectedCompetitionId)}
          onEdit={data => onEditCompetition(selectedCompetitionId, data)}
        />
      )}
    </>
  );
};
