import React, { useState, useEffect } from 'react';
import { Link as RouterLink, useRouteMatch } from 'react-router-dom';

import {
  Container,
  Typography,
  List,
  ListItem,
  ListItemText,
} from '@material-ui/core';

import { competitionsService } from '../../services';

export function CompeteHome() {
  const match = useRouteMatch();

  const [onGoingCompetitions, setOnGoingCompetitions] = useState([]);
  const [incomingCompetitions, setIncomingCompetitions] = useState([]);
  const [finishedCompetitions, setFinishedCompetitions] = useState([]);

  useEffect(() => {
    competitionsService
      .getAll('ongoing')
      .then(data => setOnGoingCompetitions(data));

    competitionsService
      .getAll('incoming')
      .then(data => setIncomingCompetitions(data));

    competitionsService
      .getAll('finished')
      .then(data => setFinishedCompetitions(data));
  }, []);

  return (
    <Container>
      <Typography variant='h1'>Let's compete!</Typography>

      <Typography variant='h2'>Compétitions en cours</Typography>
      <List>
        {onGoingCompetitions.map(({ id, title, start, end }) => (
          <ListItem
            button
            key={id}
            component={RouterLink}
            to={`${match.url}/${id}`}
          >
            <ListItemText
              primary={title}
              secondary={`Du ${new Date(start).toLocaleString()} au ${new Date(
                end
              ).toLocaleString()}`}
            />
          </ListItem>
        ))}
      </List>

      <Typography variant='h2'>Compétitions à venir</Typography>
      <List>
        {incomingCompetitions.map(({ id, title, start, end }) => (
          <ListItem
            button
            key={id}
            component={RouterLink}
            to={`${match.url}/${id}`}
          >
            <ListItemText
              primary={title}
              secondary={`Du ${new Date(start).toLocaleString()} au ${new Date(
                end
              ).toLocaleString()}`}
            />
          </ListItem>
        ))}
      </List>

      <Typography variant='h2'>Compétitions terminées</Typography>
      <List>
        {finishedCompetitions.map(({ id, title, start, end }) => (
          <ListItem
            button
            key={id}
            component={RouterLink}
            to={`${match.url}/${id}`}
          >
            <ListItemText
              primary={title}
              secondary={`Du ${new Date(start).toLocaleString()} au ${new Date(
                end
              ).toLocaleString()}`}
            />
          </ListItem>
        ))}
      </List>
    </Container>
  );
}
