import React from 'react';
import PropTypes from 'prop-types';
import {
  Typography,
  makeStyles,
  Container,
  Box,
  Button,
} from '@material-ui/core';

const useStyles = makeStyles(() => ({
  Banner: {
    height: '10em',
    width: '100%',
    backgroundColor: '#FFA911',
    boxShadow: 'inset 0 -10px 10px -10px black',
    padding: '0 5em 0 5em',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  styledTitle: {
    margin: '2em 0em 2em 0em',
    borderBottom: '2px solid #FFA911',
    fontSize: '1.75em',
    fontWeight: '500',
  },
  styledButton: {
    width: '35%',
    minWidth: '12em',
    height: '3.5em',
    color: 'black',
    backgroundColor: '#C0C0C0',
    transition: '0.3s ease',
    '&:hover': {
      color: 'white',
      backgroundColor: '#FFA911',
    },
  },
}));

const Banner = ({ children }) => {
  const classes = useStyles();
  return (
    <div className={classes.Banner}>
      <Typography variant='h2' color='secondary'>
        {children}
      </Typography>
    </div>
  );
};

Banner.propTypes = {
  children: PropTypes.string.isRequired,
};

const Chapter1 = () => {
  const classes = useStyles();
  return (
    <>
      <Banner>Neural Networks - Chapter 1</Banner>
      <Container>
        <Box my={10}>
          <Typography color='primary'>
            Etenim si attendere diligenter, existimare vere de omni hac causa
            volueritis, sic constituetis, iudices, nec descensurum quemquam ad
            hanc accusationem fuisse, cui, utrum vellet, liceret, nec, cum
            descendisset, quicquam habiturum spei fuisse, nisi alicuius
            intolerabili libidine et nimis acerbo odio niteretur. Sed ego
            Atratino, humanissimo atque optimo adulescenti meo necessario,
            ignosco, qui habet excusationem vel pietatis vel necessitatis vel
            aetatis. Si voluit accusare, pietati tribuo, si iussus est,
            necessitati, si speravit aliquid, pueritiae. Ceteris non modo nihil
            ignoscendum, sed etiam acriter est resistendum.
          </Typography>
        </Box>
        <Box my={10}>
          <Typography color='primary' className={classes.styledTitle}>
            Un titre intéressant
          </Typography>
        </Box>
        <Box mb={10}>
          <Typography color='primary'>
            Etenim si attendere diligenter, existimare vere de omni hac causa
            volueritis, sic constituetis, iudices, nec descensurum quemquam ad
            hanc accusationem fuisse, cui, utrum vellet, liceret, nec, cum
            descendisset, quicquam habiturum spei fuisse, nisi alicuius
            intolerabili libidine et nimis acerbo odio niteretur. Sed ego
            Atratino, humanissimo atque optimo adulescenti meo necessario,
            ignosco, qui habet excusationem vel pietatis vel necessitatis vel
            aetatis. Si voluit accusare, pietati tribuo, si iussus est,
            necessitati, si speravit aliquid, pueritiae. Ceteris non modo nihil
            ignoscendum, sed etiam acriter est resistendum.
          </Typography>
        </Box>
        <Box mb={10}>
          <Typography color='primary'>
            Etenim si attendere diligenter, existimare vere de omni hac causa
            volueritis, sic constituetis, iudices, nec descensurum quemquam ad
            hanc accusationem fuisse, cui, utrum vellet, liceret, nec, cum
            descendisset, quicquam habiturum spei fuisse, nisi alicuius
            intolerabili libidine et nimis acerbo odio niteretur. Petit exemple
            de liste :
          </Typography>
          <ul>
            <li>
              <Typography color='primary'>Element 1</Typography>
            </li>
            <li>
              <Typography color='primary'>Element 2</Typography>
            </li>
            <li>
              <Typography color='primary'>Element 3</Typography>
            </li>
            <li>
              <Typography color='primary'>Element 4</Typography>
            </li>
            <li>
              <Typography color='primary'>Element 5</Typography>
            </li>
          </ul>
        </Box>
        <Box my={10} display='flex' justifyContent='center'>
          <Button className={classes.styledButton}>
            <Typography variant='subtitle2'>Chapitre suivant</Typography>
          </Button>
        </Box>
      </Container>
    </>
  );
};

export default Chapter1;
