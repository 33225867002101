import React, {
  useEffect,
  createContext,
  useContext,
  useState,
  useCallback,
} from 'react';
import { authenticationService, userService } from '../services';

const authContext = createContext();

export function useAuth() {
  return useContext(authContext);
}

export function AuthProvider({ children }) {
  const [currentUser, setCurrentUser] = useState(null);
  const [initialized, setInitialized] = useState(false);

  useEffect(() => {
    authenticationService
      .renewAccessToken()
      .then(() =>
        userService
          .getCurrentUser()
          .then(user => {
            setCurrentUser(user);
          })
          .catch(() => {})
      )
      .catch(() => {})
      .finally(() => {
        setInitialized(true);
      });
  }, []);

  const signout = useCallback(async () => {
    await authenticationService.signout();
    setCurrentUser(null);
  }, []);

  const signin = useCallback(async (username, password) => {
    await authenticationService.signin(username, password);
    const user = await userService.getCurrentUser();
    setCurrentUser(user);
  }, []);

  const updateUser = useCallback(
    async (id, data) =>
      userService.updateUser(id, data).then(user => {
        setCurrentUser(user);
        return user;
      }),
    []
  );

  return (
    <authContext.Provider value={{ currentUser, signout, signin, updateUser }}>
      {initialized ? children : 'Loading ...'}
    </authContext.Provider>
  );
}
