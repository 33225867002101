import { Button, IconButton } from '@material-ui/core';
import React, { useState } from 'react';

import { ConfirmationDialog } from './ConfirmationDialog';

function withConfirmation(Component) {
  function ComponentWithConfirmation({ dialogProps, onClick, ...props }) {
    const [open, setOpen] = useState(false);

    return (
      <>
        <Component {...props} onClick={() => setOpen(true)} />
        <ConfirmationDialog
          {...dialogProps}
          open={open}
          onCancel={() => setOpen(false)}
          onConfirm={() => {
            onClick();
            setOpen(false);
          }}
        />
      </>
    );
  }

  return ComponentWithConfirmation;
}

export const ButtonWithConfirmation = withConfirmation(Button);
export const IconButtonWithConfirmation = withConfirmation(IconButton);
