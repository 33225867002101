import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@material-ui/core';
import React from 'react';

export function ConfirmationDialog({
  open,
  title,
  body,
  onConfirm,
  onCancel,
  confirmText,
  cancelText,
}) {
  return (
    <Dialog open={open} onClose={onCancel}>
      <DialogTitle>{title}</DialogTitle>
      {body && (
        <DialogContent>
          <DialogContentText>{body}</DialogContentText>
        </DialogContent>
      )}
      <DialogActions>
        <Button onClick={onCancel}>{cancelText || 'Non'}</Button>
        <Button onClick={onConfirm}>{confirmText || 'Oui'}</Button>
      </DialogActions>
    </Dialog>
  );
}
