import React from 'react';
import {
  makeStyles,
  Typography,
  TextField,
  Button,
  Grid,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
} from '@material-ui/core';
import { MuiPickersUtilsProvider, DateTimePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import Markdown from 'markdown-to-jsx';

import { Formik, Field, Form } from 'formik';
import * as Yup from 'yup';

import { competitionsService } from '../../../../services';

const useStyle = makeStyles(theme => ({
  form: {
    '& > *': {
      marginTop: theme.spacing(1),
      marginBottem: theme.spacing(1),
    },
  },
  textfield: {
    color: '#FFA911',
    backgroundColor: 'red',
  },
  rightAlign: {
    textAlign: 'right',
  },
  submitButton: {
    color: 'white',
    '&:hover': {
      backgroundColor: '#f7a400',
    },
  },
}));

const schema = Yup.object().shape({
  title: Yup.string().required(),
  description: Yup.string().required(),
  start: Yup.date().required(),
  end: Yup.date().required(),
  context: Yup.mixed().required(),
  type: Yup.mixed().oneOf(['score', 'model']),
});

export function CreateCompetitionDialog({ open, onClose, onAdd }) {
  const classes = useStyle();

  const handleSubmit = async (values, actions) => {
    competitionsService
      .createCompetition(values)
      .then(competition => {
        actions.resetForm();
        onAdd(competition);
      })
      .catch(error => {
        console.log(error);
        actions.setStatus('An error occurred while creating the competition.');
      });
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth='lg' fullWidth>
      <DialogTitle>Créer une nouvelle compétition</DialogTitle>

      <DialogContent>
        <Formik
          autoComplete='off'
          noValidate
          onSubmit={handleSubmit}
          initialValues={{
            title: '',
            description: '',
            start: new Date(),
            end: new Date(),
            type: 'score',
          }}
          validationSchema={schema}
        >
          {({ values, setFieldValue, errors, status }) => (
            <Form className={classes.form}>
              <Field name='title'>
                {({ field, meta }) => (
                  <TextField
                    label='Titre de la compétition'
                    {...field}
                    fullWidth
                    required
                    autoFocus
                    error={meta.touched && meta.error != null}
                    helperText={meta.touched && meta.error ? meta.error : ''}
                  />
                )}
              </Field>
              <Field name='description'>
                {({ field, meta }) => (
                  <TextField
                    label='Description de la compétition'
                    {...field}
                    fullWidth
                    required
                    variant='outlined'
                    multiline
                    rows={5}
                    error={meta.touched && meta.error != null}
                    helperText={meta.touched && meta.error ? meta.error : ''}
                  />
                )}
              </Field>
              <Markdown>{values.description}</Markdown>

              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <Grid container spacing={2}>
                  <Grid item md={6}>
                    <DateTimePicker
                      fullWidth
                      name='start'
                      value={values.start}
                      label='Début de la compétition'
                      onChange={date => {
                        if (date > values.end) setFieldValue('end', date);
                        setFieldValue('start', date);
                      }}
                    />
                  </Grid>
                  <Grid item md={6}>
                    <DateTimePicker
                      fullWidth
                      name='end'
                      value={values.end}
                      label='Fin de la compétition'
                      onChange={date => {
                        if (date < values.start) setFieldValue('start', date);
                        setFieldValue('end', date);
                      }}
                    />
                  </Grid>
                </Grid>
              </MuiPickersUtilsProvider>

              <Field name='type'>
                {({ field }) => (
                  <FormControl>
                    <InputLabel>Type</InputLabel>
                    <Select {...field}>
                      <MenuItem value='score'>Score</MenuItem>
                      <MenuItem value='model'>Modèle</MenuItem>
                    </Select>
                  </FormControl>
                )}
              </Field>

              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography>
                    Cela doit être un fichier zip contenant un dossier
                    "context".
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Button fullWidth variant='outlined' component='label'>
                    Context folder for building the Docker image
                    <input
                      type='file'
                      accept='.zip'
                      onChange={e => {
                        setFieldValue('context', e.target.files[0]);
                      }}
                      style={{ display: 'none' }}
                    />
                  </Button>
                </Grid>
                <Grid item xs={12}>
                  {errors.context}
                </Grid>
              </Grid>

              <Button
                className={classes.submitButton}
                variant='contained'
                fullWidth
                color='primary'
                type='submit'
              >
                Créer la compétition
              </Button>

              {status && (
                <Typography variant='caption' style={{ color: 'red' }}>
                  {status}
                </Typography>
              )}
            </Form>
          )}
        </Formik>
      </DialogContent>
    </Dialog>
  );
}
