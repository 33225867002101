import { api } from './authentication';

export function getAllTeams(competitionId) {
  return api
    .get('/api/team', { params: { competitionId } })
    .then(response => response.data);
}

export function deleteTeam(id) {
  return api.delete(`/api/team/${id}`);
}

export function getMyTeam(competitionId) {
  return api
    .get('/api/team/me', { params: { competitionId } })
    .then(response => response.data)
    .catch(error => {
      // Not 404 axios error
      if (!error.response || error.response.status !== 404) {
        throw error;
      }

      return null;
    });
}

export function getMyTeamScores(teamId) {
  return api.get(`/api/team/${teamId}/scores`).then(response => response.data);
}

export function createTeam(name, competitionId) {
  return api
    .post('/api/team', { name, competitionId })
    .then(response => response.data);
}

export function addTeamMember(teamId, competitionId, username) {
  return api
    .post(`/api/team/${teamId}/add-member`, { username, competitionId })
    .then(response => response.data);
}
