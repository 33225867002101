import axios from 'axios';
import { api } from './authentication';

// New stuff

function getCurrentUser() {
  return api.get('/api/user/me').then(response => response.data);
}

function updatePassword(id, currentPassword, newPassword) {
  return api.post(`/api/user/${id}/update-password`, {
    currentPassword,
    newPassword,
  });
}

function updateUser(id, { username }) {
  return api
    .post(`/api/user/${id}`, {
      username,
    })
    .then(response => response.data);
}

function getAllUsers() {
  return api.get('/api/user').then(response => response.data);
}

function updateUserRole(id, role) {
  return api
    .put(`/api/user/${id}/update-role`, { role })
    .then(response => response.data);
}

function generateResetPasswordToken(id) {
  return api
    .get(`/api/user/${id}/forgotten-password`)
    .then(response => response.data);
}

export function resetPassword(token, password) {
  return api
    .post('/api/user/reset-password', { token, password })
    .then(response => response.data);
}

// Old stuff

const isUsernameAvailable = async username => {
  try {
    await axios.get(`/auth/username-available/${username}`);
    return true;
  } catch (e) {
    return false;
  }
};

// const updateProfile = async ({ id, username, password, email }) => {
//   const config = {
//     headers: authHeader(),
//   };

//   return axios
//     .post(
//       `/api/users/${id}`,
//       {
//         username,
//         email,
//         password,
//       },
//       config
//     )
//     .then(res => {
//       authenticationService.updateUser(res.data);
//       return res.data;
//     });
// };

export default {
  isUsernameAvailable,
  // updateProfile,
  updateUserRole,
  updatePassword,
  getAllUsers,
  getCurrentUser,
  updateUser,
  generateResetPasswordToken,
  resetPassword,
};
