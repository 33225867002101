import React, { useState } from 'react';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import { IconButton, TextField } from '@material-ui/core';

const PasswordField = ({ ...props }) => {
  const [passwordVisible, setPasswordVisible] = useState(false);
  const updatePasswordVisible = e => {
    e.preventDefault();
    setPasswordVisible(!passwordVisible);
  };

  return (
    <TextField
      label='Mot de passe'
      name='password'
      type={passwordVisible ? 'text' : 'password'}
      autoComplete='new-password'
      InputProps={{
        endAdornment: (
          <IconButton
            onClick={updatePasswordVisible}
            onMouseDown={e => {
              e.preventDefault();
            }}
          >
            {passwordVisible ? <Visibility /> : <VisibilityOff />}
          </IconButton>
        ),
      }}
      {...props}
    />
  );
};

export default PasswordField;
