import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Typography,
  makeStyles,
  Container,
  Box,
  Hidden,
  Link,
} from '@material-ui/core';

import {
  Link as RouterLink,
  useRouteMatch,
  useParams,
  Switch,
  Route,
} from 'react-router-dom';

import { courseService } from '../services';
import { StrongOrange } from '../display/Theme';
import DummySummary from './DummyCourse/Summary';
import DummyChapter1 from './DummyCourse/Chapter1';

const useStyles = makeStyles(() => ({
  classBox: {
    borderRadius: '8px',
    display: 'flex',
    height: '10em',
    backgroundColor: 'white',
    transition: '0.3s ease',
    alignItems: 'center',
    justifyContent: 'left',
    textDecoration: 'none',
    boxShadow: '3px 3px 5px black',
    margin: '3em 0 -1em 0',
    '&:hover': {
      boxShadow: '3px 3px 10px black',
    },
    '& img': {
      flex: '0.5',
      minWidth: '5em',
      maxWidth: '8em',
      margin: '0 1em 0 1em',
    },
    '& div': {
      flex: '1',
      margin: '0 0.5em 0 0.5em',
    },
    '& p': {
      flex: '2',
      flexGrow: '2',
    },
  },
  info: {
    display: 'flex',
    flexDirection: 'column',
  },
}));

/*
const CoursesList = () => {
  const [loading, setLoading] = useState(true);
  const [courses, setCourses] = useState([]);
  const [course, setCourse] = useState({
    title: 'Ahoy!',
    description: 'desc2',
  });

  // Add Course API + logic
  const addCourse = e => {
    e.preventDefault();

    if (!course) {
      console.log('Please write something!');
      return;
    }

    // To refactor in an API file
    axios.post('/api/courses', { ...course }).then(newCourse => {
      console.log(newCourse.data.course);
      setCourses([...courses, newCourse.data.course]);
      setCourse({ title: '', description: '' });
    });
  };

  // Remove Course API + logic
  const removeCourse = (e, id) => {
    e.preventDefault();

    axios.delete(`/api/courses/${id}`).then(res => {
      console.log(`Course #${res.data.id} deleted`);
      setCourses(courses.filter(({ id: _id }) => id !== _id));
    });
  };

  // Update Course API + logic
  const updateCourse = (e, id) => {
    e.preventDefault();

    axios
      .put(`/api/courses/${id}`, { ...course })
      .then(res => {
        console.log('updated course', res.data.course);
        setCourses(courses.map(c => (c.id === id ? res.data.course : c)));
      })
      .catch(console.log);
  };

  return (
    <>
      <AddCourse setCourse={setCourse} course={course} addCourse={addCourse} />
      <button type='button' onClick={e => updateCourse(e, 6)}>
        Update
      </button>
      <div>
        {courses.map(item => (
          <Course key={item.id} {...item} removeCourse={removeCourse} />
        ))}
      </div>
    </>
  );
};

const AddCourse = ({ addCourse, setCourse, course }) => {
  if (!course) setCourse({ title: 'Hello there', description: "It's me!" });

  const updateTitle = e => {
    setCourse({ title: e.target.value, description: course.description });
  };

  const updateDescription = e => {
    setCourse({ title: course.title, description: e.target.value });
  };

  return (
    <form>
      <label htmlFor='title'>
        Title :{' '}
        <input
          id='title'
          type='text'
          onChange={updateTitle}
          value={course.title}
        />
      </label>
      <br />
      <label htmlFor='desc'>
        Description :
        <br />
        <textarea
          id='desc'
          onChange={updateDescription}
          value={course.description}
        />
        <br />
      </label>
      <input onClick={addCourse} value='Ajouter' type='button' />
    </form>
  );
};

AddCourse.propTypes = {
  addCourse: PropTypes.func.isRequired,
  setCourse: PropTypes.func.isRequired,
  course: PropTypes.shape({
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
  }).isRequired,
};

const LearnHome = () => {
  return (
    <div>
      <h1>Learn Home (WIP)</h1>
      <CoursesList />
    </div>
  );
};

*/

const CourseTile = ({
  title,
  difficulty,
  srcImg,
  description,
  id,
  updatedAt,
  createdAt,
}) => {
  const match = useRouteMatch();
  const classes = useStyles();

  return (
    <RouterLink to={`${match.url}/${id}`} className={classes.classBox}>
      <img src={srcImg} alt='' />
      <div className={classes.info}>
        <Typography variant='h4'>
          <StrongOrange>{title}</StrongOrange>
        </Typography>
        <Typography color='primary'>Difficulty : {difficulty}</Typography>
      </div>
      <Hidden xsDown>
        <Typography color='primary'>{description}</Typography>
        <br />
        <p style={{ display: 'none' }}>
          {updatedAt} | {createdAt}
        </p>
      </Hidden>
    </RouterLink>
  );
};

CourseTile.propTypes = {
  title: PropTypes.string.isRequired,
  difficulty: PropTypes.string.isRequired,
  updatedAt: PropTypes.instanceOf(Date).isRequired,
  createdAt: PropTypes.instanceOf(Date).isRequired,
  id: PropTypes.number.isRequired,
  srcImg: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
};

const LearnHome = () => {
  const match = useRouteMatch();
  const { path } = match;

  const [loading, setLoading] = useState(true);
  const [courses, setCourses] = useState([]);

  // Get all courses
  useEffect(() => {
    courseService.getAll().then(res => {
      setLoading(false);
      console.log(res.status, res.data.courses);
      setCourses(res.data.courses);
    });
  }, []);

  if (loading) {
    return 'loading';
  }

  return (
    <>
      <Container>
        <Box mt={12} mb={3}>
          <Typography variant='h2'>
            Here we <StrongOrange>learn</StrongOrange> again
          </Typography>
        </Box>
      </Container>

      <Switch>
        {/* Dummy Course Routing */}
        <Route path={`${path}/fake-id/1`}>
          <DummyChapter1 />
        </Route>
        <Route path={`${path}/fake-id`}>
          <DummySummary />
        </Route>

        {/* Prod routing */}
        <Route path={`${path}/:id`}>
          <Course />
        </Route>
        <Route path={`${path}`}>
          {/* TODO move that to a component */}
          <>
            <CourseTile
              title='Dummy course'
              difficulty='Easy'
              srcImg='logo512.png'
              description='Cette formation ne cécessite aucun prérequies et vous permet de
            découvrir le Machine Learning et de petits programmes python en
            utilisant la librairie Scikit-Learn'
              id='fake-id'
              updatedAt={Date()}
              createdAt={Date()}
            />
            {courses.map(item => (
              <CourseTile
                href='#/'
                difficulty='easy'
                srcImg='logo512.png'
                key={item.id}
                {...item}
              />
            ))}
          </>
        </Route>
      </Switch>
    </>
  );
};

const Course = () => {
  const { id } = useParams();
  const [course, setCourse] = useState({});
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    courseService
      .get(id)
      .then(res => {
        setLoading(false);
        setCourse(res.data.course);
      })
      .catch(console.log);
  }, [id]);

  if (loading) return 'Loading';
  return (
    <div>
      <Typography variant='h2'>{course.title}</Typography>
      <Typography variant='subtitle1'>{course.description}</Typography>
      <br />
      <Link component={RouterLink} to='/learn'>
        Retour
      </Link>
    </div>
  );
};

export default LearnHome;
