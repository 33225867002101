import React from 'react';
import { Button, Grid, Typography } from '@material-ui/core';

import { Formik, Form } from 'formik';
import * as Yup from 'yup';

import { notificationService } from '../../../../services';
import { updateCompetitionPredictions } from '../../../../services/competitions';

const schemaPredictions = Yup.object().shape({
  context: Yup.mixed().required(),
});

export function UpdateCompetitionContextButtons({ competitionId, onEdit }) {
  return (
    <Formik
      autoComplete='off'
      noValidate
      onSubmit={(values, actions) => {
        updateCompetitionPredictions(competitionId, values.context).then(
          updatedCompetition => {
            onEdit(updatedCompetition);
            actions.resetForm();
            notificationService.addNotification('Compétition mise à jour !');
          }
        );
      }}
      initialValues={{ context: null }}
      validationSchema={schemaPredictions}
    >
      {({ setFieldValue, values, errors }) => (
        <Form>
          <Grid container spacing={3}>
            <Grid item xs={values.context ? 6 : 12}>
              <Button
                fullWidth
                variant='outlined'
                component='label'
                style={{ textTransform: 'initial' }}
              >
                {values.context ? (
                  <>
                    {values.context.name} (
                    {(values.context.size / 1024 / 1024).toFixed(2)}Mo )
                  </>
                ) : (
                  <>Mettre à jour l'image Docker d'une compétition</>
                )}

                <input
                  type='file'
                  onChange={e => {
                    setFieldValue('context', e.target.files[0]);
                  }}
                  accept='.zip'
                  style={{ display: 'none' }}
                />
              </Button>
            </Grid>

            <Grid item xs={6} hidden={!values.context}>
              <Button
                variant='contained'
                fullWidth
                color='primary'
                type='submit'
              >
                Mettre à jour
              </Button>
            </Grid>
            {errors.context && (
              <Grid item xs={6}>
                {errors.context}
              </Grid>
            )}
          </Grid>
          <Typography variant='caption' color='textSecondary'>
            Il doit s'agir d'un fichier zip contenant un dossier context
            permettant de build l'image Docker de la compétition.
            <br />
            <b>
              Les soumissions actuelles ne seront pas recalculées avec cette
              nouvelle image.
            </b>
          </Typography>
        </Form>
      )}
    </Formik>
  );
}
