import React from 'react';
import PropTypes from 'prop-types';
import {
  Typography,
  makeStyles,
  Container,
  Box,
  Link,
  Button,
} from '@material-ui/core';

import { Link as RouterLink, useRouteMatch } from 'react-router-dom';

const useStyles = makeStyles(() => ({
  Banner: {
    height: '10em',
    width: '100%',
    backgroundColor: '#FFA911',
    boxShadow: 'inset 0 -10px 10px -10px black',
    padding: '0 5em 0 5em',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  styledList: {
    '& li': {
      margin: '1em 0em 1em 0em',
    },
    '&>li': {
      margin: '2em 0em 2em 0em',
      borderBottom: '2px solid #FFA911',
    },
    '& a': {
      textDecoration: 'none',
    },
    '& a:hover': {
      textDecoration: 'none',
    },
  },
  styledButton: {
    width: '35%',
    minWidth: '12em',
    height: '3.5em',
    color: 'secondary',
    backgroundColor: '#C0C0C0',
    transition: '0.3s ease',
    '&:hover': {
      color: 'white',
      backgroundColor: '#FFA911',
    },
  },
}));

const Banner = ({ children }) => {
  const classes = useStyles();
  return (
    <div className={classes.Banner}>
      <Typography variant='h2' color='secondary'>
        {children}
      </Typography>
    </div>
  );
};

Banner.propTypes = {
  children: PropTypes.string.isRequired,
};

const NN = () => {
  const match = useRouteMatch();
  const classes = useStyles();

  return (
    <>
      <Banner>Neural Networks</Banner>
      <Container>
        <Box my={10}>
          <Typography color='primary'>
            Etenim si attendere diligenter, existimare vere de omni hac causa
            volueritis, sic constituetis, iudices, nec descensurum quemquam ad
            hanc accusationem fuisse, cui, utrum vellet, liceret, nec, cum
            descendisset, quicquam habiturum spei fuisse, nisi alicuius
            intolerabili libidine et nimis acerbo odio niteretur. Sed ego
            Atratino, humanissimo atque optimo adulescenti meo necessario,
            ignosco, qui habet excusationem vel pietatis vel necessitatis vel
            aetatis. Si voluit accusare, pietati tribuo, si iussus est,
            necessitati, si speravit aliquid, pueritiae. Ceteris non modo nihil
            ignoscendum, sed etiam acriter est resistendum.
          </Typography>
        </Box>
        <Box mb={10}>
          <ul className={classes.styledList}>
            <li>
              <Typography color='primary' variant='h5'>
                <Link component={RouterLink} to='#/'>
                  Partie 1
                </Link>{' '}
                {/* Dummy link */}
              </Typography>
            </li>
            <ul>
              <li>
                <Link component={RouterLink} to='#/'>
                  Sous partie 1
                </Link>
              </li>
              <li>
                <Link component={RouterLink} to='#/'>
                  Sous partie 2
                </Link>
              </li>
              <li>
                <Link component={RouterLink} to='#/'>
                  Sous partie 3
                </Link>
              </li>
              <li>
                <Link component={RouterLink} to='#/'>
                  Sous partie 4
                </Link>
              </li>
              <li>
                <Link component={RouterLink} to='#/'>
                  Sous partie 5
                </Link>
              </li>
            </ul>
            <li>
              <Typography color='primary' variant='h5'>
                <Link component={RouterLink} to='#/'>
                  Partie 2
                </Link>
              </Typography>
            </li>
            <ul>
              <li>
                <Link component={RouterLink} to='#/'>
                  Sous partie 1
                </Link>
              </li>
              <li>
                <Link component={RouterLink} to='#/'>
                  Sous partie 2
                </Link>
              </li>
              <li>
                <Link component={RouterLink} to='#/'>
                  Sous partie 3
                </Link>
              </li>
              <li>
                <Link component={RouterLink} to='#/'>
                  Sous partie 4
                </Link>
              </li>
              <li>
                <Link component={RouterLink} to='#/'>
                  Sous partie 5
                </Link>
              </li>
            </ul>
            <li>
              <Typography color='primary' variant='h5'>
                <Link component={RouterLink} to='#/'>
                  Partie 3
                </Link>
              </Typography>
            </li>
            <ul>
              <li>
                <Link component={RouterLink} to='#/'>
                  Sous partie 1
                </Link>
              </li>
              <li>
                <Link component={RouterLink} to='#/'>
                  Sous partie 2
                </Link>
              </li>
              <li>
                <Link component={RouterLink} to='#/'>
                  Sous partie 3
                </Link>
              </li>
              <li>
                <Link component={RouterLink} to='#/'>
                  Sous partie 4
                </Link>
              </li>
              <li>
                <Link component={RouterLink} to='#/'>
                  Sous partie 5
                </Link>
              </li>
            </ul>
          </ul>
        </Box>
        <Box my={10} display='flex' justifyContent='center'>
          <Button
            component={RouterLink}
            to={`${match.url}/1`}
            className={classes.styledButton}
          >
            <Typography variant='subtitle2'>Commencer</Typography>
          </Button>
        </Box>
      </Container>
    </>
  );
};

export default NN;
