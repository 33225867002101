import React from 'react';
import PropTypes from 'prop-types';
import { createMuiTheme, withStyles } from '@material-ui/core/styles';

const theme = createMuiTheme({
  spacing: 8,
  palette: {
    primary: {
      main: '#505050',
    },
    secondary: {
      main: '#FFFFFF',
    },
  },
});

const GlobalCss = withStyles({
  '@global': {
    '.MuiLink-root': {
      textDecoration: 'underline dotted',
      transition: '0.3s ease',
    },
    '.MuiLink-root:hover': {
      textDecoration: 'underline dotted',
      color: '#FFA911',
    },
  },
})(() => null);

const StrongOrange = ({ children }) => {
  return <strong style={{ color: '#FFA911' }}>{children}</strong>;
};

StrongOrange.propTypes = {
  children: PropTypes.string.isRequired,
};

export { theme, GlobalCss, StrongOrange };
